import { useHistory } from "react-router-dom";
import { User } from "../../../client";
import { ApplicationPaths } from "../../../constants/Application";
import { SessionStore } from "../../../data/SessionStore";
import { UserViewModel } from "../../../viewmodels/UserViewModel";
import CustomToolbar from "../toolbar/CustomToolbar";


export interface IAppSkeletonProps {
    component?: any
}

export default function AppSkeleton(props: IAppSkeletonProps) {
    const history = useHistory();
    const scrollToTop = () => {
        /** Method to move the scrool to the top after the user moves from one section to another */
        window.scrollTo(0, 0);
    }

    loadLoggedUserFromLocalStorage();
    return <>
        {scrollToTop()}
        <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <div style={{ display: "block" }}>
                {history.location.pathname !== ApplicationPaths.HOME &&
                    <CustomToolbar />
                }
                {props.component}
            </div>
        </div>
    </>
}

const loadLoggedUserFromLocalStorage = () => {
    if (UserViewModel.getInstance().loggedUser === undefined) {
        const [userId, userToken] = SessionStore.getLocalStorageUser();
        if (userId && userToken) {
            UserViewModel.getInstance().getUserById(+userId).then((user: User | undefined) => {
                if (user) {
                    UserViewModel.getInstance().setLoggedUser(user);
                }
            })
        }
    }
}