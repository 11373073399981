import { Dialog, DialogTitle, DialogActions, DialogContent, Grid, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, TypographyTheme, TextField } from "@movicoders/movicoders-components";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { UserViewModel } from "../../../../viewmodels/UserViewModel";
import { User } from "../../../../client";
import OwnColors from "../../../../constants/OwnColors";

type TPasswordForm = {
    open: boolean
    setOpen: (value: boolean) => void
    handleOpenSnackbar: (open: boolean, error: boolean, message: string) => void
}
const PasswordForm = ({ open, setOpen, handleOpenSnackbar }: TPasswordForm) => {
    const { t } = useTranslation();
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [passwordConfirmError, setPasswordConfirmError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const handleSave = () => {
        if (password == "" || password == undefined) {
            setPasswordError(true);
        } else if (passwordConfirm == "" || passwordConfirm == undefined) {
            setPasswordConfirmError(true);
        } else if (password !== passwordConfirm) {
            setPasswordConfirmError(true);
        } else {
            const user: User = UserViewModel.getInstance().loggedUser!;
            user.password = password;
            UserViewModel.getInstance().updateUser(user.id!, user)
                .then(res => {
                    handleOpenSnackbar(true, false, t("editUserView.password.success"));
                    handleClose();
                })
                .catch(err => {
                    handleOpenSnackbar(true, true, t("editUserView.password.error"));
                })
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
            <DialogTitle style={{backgroundColor: "black", color: "white"}}>{t("editUserView.form.password.title")}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TypographyTheme text={t("editUserView.form.password")} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            onChange={(event: any) => { setPassword(event.target.value) }}
                            width="100%"
                            type={showPassword ? "text" : "password"}
                            error={passwordError}
                            errorText={t("editUserView.password.error.empty")}
                            inputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => { setShowPassword(!showPassword) }}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }} />
                    </Grid>
                    <Grid item xs={12}>
                        <TypographyTheme text={t("editUserView.form.passwordConfirm")} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            onChange={(event: any) => { setPasswordConfirm(event.target.value) }}
                            width="100%"
                            type={showPasswordConfirm ? "text" : "password"}
                            error={passwordConfirmError}
                            errorText={passwordConfirm == "" ? t("editUserView.password.error.empty") : t("editUserView.password.error.notEqual")}
                            inputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => { setShowPasswordConfirm(!showPasswordConfirm) }}
                                        >
                                            {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    text={t("editUserView.form.cancel")}
                    onClick={handleClose}
                    style={{ fontWeight: "bold" }}
                    textColor="white !important"
                    hoverColor="white !important"
                    backgroundColor={`${OwnColors.buttonBlack} !important`}
                    hoverBackgroundColor={`${OwnColors.buttonBlackHover}  !important`} />
                <Button
                    text={t("editUserView.form.save")}
                    onClick={handleSave}
                    style={{ fontWeight: "bold" }}
                    textColor="white !important"
                    hoverColor="white !important"
                    backgroundColor={`${OwnColors.buttonBlack} !important`}
                    hoverBackgroundColor={`${OwnColors.buttonBlackHover}  !important`} />
            </DialogActions>
        </Dialog>
    );
}

export default PasswordForm;