import { Button } from "@movicoders/movicoders-components";
import logo from "../../../assets/images/logo.png"
import { useHistory } from "react-router-dom";
import { Grid, AppBar, Toolbar } from '@mui/material';
import { ApplicationPaths, ToolbarHeight } from "../../../constants/Application";
import OwnColors from "../../../constants/OwnColors";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import CustomDrawer from "./customDrawer/CustomDrawer";

type TCustomtoolbar = {
    startPreview?: () => void
    handleBuy?: () => void;
}
const Customtoolbar = ({ startPreview, handleBuy }: TCustomtoolbar) => {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div style={{ width: '100%', margin: 0, padding: 0, flexGrow: 1, display: "flex", zIndex: 13000, height: ToolbarHeight }}>
            <AppBar position="static" elevation={0}>
                <Toolbar style={{ backgroundColor: OwnColors.podoactivaBlack, display: "flex", justifyContent: "space-between" }}>
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" style={{ height: "3.5rem", imageRendering: "-webkit-optimize-contrast" }} />
                    </Grid>
                    <Grid container direction="row" item xs={6} style={{ columnGap: "20px" }} justifyContent="flex-end">
                        {history.location.pathname === ApplicationPaths.HOME &&
                            <Button
                                text={"Previsualizar en 3D"}
                                onClick={startPreview!}
                                style={{ fontWeight: "bold", border: "2px solid white" }}
                                textColor="white !important"
                                hoverColor="white !important"
                                backgroundColor={`${OwnColors.buttonBlack} !important`}
                                hoverBackgroundColor={`${OwnColors.buttonBlackHover}  !important`}
                            />}
                        {history.location.pathname === ApplicationPaths.HOME &&
                            <Button
                                text={"Comprar"}
                                onClick={handleBuy!}
                                style={{ fontWeight: "bold" }}
                                textColor="black !important"
                                hoverColor="black !important"
                                backgroundColor={`white !important`}
                                hoverBackgroundColor={`lightgrey !important`}
                            />}
                        {history.location.pathname !== ApplicationPaths.HOME &&
                            <Button
                                text={t("shinpadTemplateView.toolbar.custom")}
                                onClick={() => history.push("/home")}
                                style={{ fontWeight: "bold", border: "2px solid white" }}
                                textColor="white !important"
                                hoverColor="white !important"
                                backgroundColor={`${OwnColors.buttonBlack} !important`}
                                hoverBackgroundColor={`${OwnColors.buttonBlackHover}  !important`}
                            />
                        }
                        <CustomDrawer />
                    </Grid>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default observer(Customtoolbar);