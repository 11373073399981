// Constants in proccessing images to create models
const ModelDim = {

    DESIGN_DIM: "637x1305",

    DESIGN_COORD_RIGHT_FRONT: "+930+20",

    DESIGN_COORD_LEFT_FRONT: "+349+30",

    DESIGN_COORD_RIGHT_SIDE: "+740+40",

    DESIGN_COORD_LEFT_SIDE: "+270+40",

    DESIGN_COORD_RIGHT_SIDE2: "+980+40",

    DESIGN_COORD_LEFT_SIDE2: "+500+40",

    DESIGN_FUZZ: "1%",

}

export default ModelDim;
