/* tslint:disable */
/* eslint-disable */
/**
 * Podoactiva Backend Api Documentation
 * Podoactiva Backend Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Image,
    ImageFromJSON,
    ImageToJSON,
} from '../models';

export interface CreateImageRequest {
    storeS3?: boolean;
    image?: Blob;
}

export interface DeleteImageByIdRequest {
    id: number;
}

export interface DownloadImageByIdRequest {
    id: number;
}

export interface GetImageByIdRequest {
    id: number;
}

/**
 * no description
 */
export class ImageApi extends runtime.BaseAPI {

    /**
     * Creates a image
     * createImage
     */
    async createImageRaw(requestParameters: CreateImageRequest): Promise<runtime.ApiResponse<Image>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.storeS3 !== undefined) {
            queryParameters['storeS3'] = requestParameters.storeS3;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.image !== undefined) {
            formParams.append('image', requestParameters.image as any);
        }

        const response = await this.request({
            path: `/images`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageFromJSON(jsonValue));
    }

    /**
     * Creates a image
     * createImage
     */
    async createImage(storeS3?: boolean, image?: Blob): Promise<Image> {
        const response = await this.createImageRaw({ storeS3: storeS3, image: image });
        return await response.value();
    }

    /**
     * Deletes a image by its id.
     * deleteImageById
     */
    async deleteImageByIdRaw(requestParameters: DeleteImageByIdRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteImageById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/images/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a image by its id.
     * deleteImageById
     */
    async deleteImageById(id: number): Promise<void> {
        await this.deleteImageByIdRaw({ id: id });
    }

    /**
     * Sends image data
     * downloadImageById
     */
    async downloadImageByIdRaw(requestParameters: DownloadImageByIdRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling downloadImageById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/download/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Sends image data
     * downloadImageById
     */
    async downloadImageById(id: number): Promise<string> {
        const response = await this.downloadImageByIdRaw({ id: id });
        return await response.value();
    }

    /**
     * Recovers all images
     * getAllImages
     */
    async getAllImagesRaw(): Promise<runtime.ApiResponse<Array<Image>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/images`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ImageFromJSON));
    }

    /**
     * Recovers all images
     * getAllImages
     */
    async getAllImages(): Promise<Array<Image>> {
        const response = await this.getAllImagesRaw();
        return await response.value();
    }

    /**
     * Recovers a image by its id
     * getImageById
     */
    async getImageByIdRaw(requestParameters: GetImageByIdRequest): Promise<runtime.ApiResponse<Image>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getImageById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/images/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageFromJSON(jsonValue));
    }

    /**
     * Recovers a image by its id
     * getImageById
     */
    async getImageById(id: number): Promise<Image> {
        const response = await this.getImageByIdRaw({ id: id });
        return await response.value();
    }

}
