/* tslint:disable */
/* eslint-disable */
/**
 * Podoactiva Backend Api Documentation
 * Podoactiva Backend Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Image,
    ImageFromJSON,
    ImageFromJSONTyped,
    ImageToJSON,
} from './';

/**
 * Resource
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * 
     * @type {number}
     * @memberof Resource
     */
    id?: number;
    /**
     * 
     * @type {Image}
     * @memberof Resource
     */
    image?: Image;
    /**
     * 
     * @type {Image}
     * @memberof Resource
     */
    icon?: Image;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    type?: ResourceTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    active?: boolean;
}

export function ResourceFromJSON(json: any): Resource {
    return ResourceFromJSONTyped(json, false);
}

export function ResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Resource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'image': !exists(json, 'image') ? undefined : ImageFromJSON(json['image']),
        'icon': !exists(json, 'icon') ? undefined : ImageFromJSON(json['icon']),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'active': !exists(json, 'active') ? undefined : json['active'],
    };
}

export function ResourceToJSON(value?: Resource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'image': ImageToJSON(value.image),
        'icon': ImageToJSON(value.icon),
        'type': value.type,
        'active': value.active,
    };
}

/**
* @export
* @enum {string}
*/
export enum ResourceTypeEnum {
    BACKGROUND = 'BACKGROUND',
    ELEMENT = 'ELEMENT'
}


