import { Button } from "@movicoders/movicoders-components";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";

type TConfirmDialog = {
    handleConfirm: () => void
    onClose: () => void
    open: boolean
    title: string
    body: string
}
const ConfirmDialog = ({ handleConfirm, onClose, open, title, body }: TConfirmDialog) => {
    const { t } = useTranslation();

    return(
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{body}</DialogContent>
            <DialogActions>
                <Button text={t("confirmDialog.cancel")} onClick={onClose} />
                <Button text={t("confirmDialog.confirm")} onClick={handleConfirm} />
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog;