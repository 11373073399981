/* tslint:disable */
/* eslint-disable */
/**
 * Podoactiva Backend Api Documentation
 * Podoactiva Backend Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Response obtained when login works.
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    username?: string;
    /**
     * 
     * @type {number}
     * @memberof LoginResponse
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    profile?: LoginResponseProfileEnum;
}

export function LoginResponseFromJSON(json: any): LoginResponse {
    return LoginResponseFromJSONTyped(json, false);
}

export function LoginResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': !exists(json, 'token') ? undefined : json['token'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'profile': !exists(json, 'profile') ? undefined : json['profile'],
    };
}

export function LoginResponseToJSON(value?: LoginResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'username': value.username,
        'userId': value.userId,
        'profile': value.profile,
    };
}

/**
* @export
* @enum {string}
*/
export enum LoginResponseProfileEnum {
    SHOP = 'SHOP',
    ADMIN = 'ADMIN'
}


