/* tslint:disable */
/* eslint-disable */
/**
 * Podoactiva Backend Api Documentation
 * Podoactiva Backend Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Resource,
    ResourceFromJSON,
    ResourceToJSON,
} from '../models';

export interface CreateResourceRequest {
    resource?: Resource;
}

export interface DeleteResourceByIdRequest {
    id: number;
}

export interface GetResourceByIdRequest {
    id: number;
}

export interface GetResourceByTypeRequest {
    type?: GetResourceByTypeTypeEnum;
}

export interface UpdateResourceRequest {
    id: number;
    resource?: Resource;
}

/**
 * no description
 */
export class ResourceApi extends runtime.BaseAPI {

    /**
     * Creates a resource
     * createResource
     */
    async createResourceRaw(requestParameters: CreateResourceRequest): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/resources`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResourceToJSON(requestParameters.resource),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * Creates a resource
     * createResource
     */
    async createResource(resource?: Resource): Promise<Resource> {
        const response = await this.createResourceRaw({ resource: resource });
        return await response.value();
    }

    /**
     * Deletes a resource by its id.
     * deleteResourceById
     */
    async deleteResourceByIdRaw(requestParameters: DeleteResourceByIdRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteResourceById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/resources/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a resource by its id.
     * deleteResourceById
     */
    async deleteResourceById(id: number): Promise<void> {
        await this.deleteResourceByIdRaw({ id: id });
    }

    /**
     * Retrieves all Resources
     * getAllResources
     */
    async getAllResourcesRaw(): Promise<runtime.ApiResponse<Array<Resource>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/resources`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResourceFromJSON));
    }

    /**
     * Retrieves all Resources
     * getAllResources
     */
    async getAllResources(): Promise<Array<Resource>> {
        const response = await this.getAllResourcesRaw();
        return await response.value();
    }

    /**
     * Retrieves all Resources
     * getResourceById
     */
    async getResourceByIdRaw(requestParameters: GetResourceByIdRequest): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getResourceById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/resources/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * Retrieves all Resources
     * getResourceById
     */
    async getResourceById(id: number): Promise<Resource> {
        const response = await this.getResourceByIdRaw({ id: id });
        return await response.value();
    }

    /**
     * Retrieves a list of resources by its type
     * getResourceByType
     */
    async getResourceByTypeRaw(requestParameters: GetResourceByTypeRequest): Promise<runtime.ApiResponse<Array<Resource>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/resources/type`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResourceFromJSON));
    }

    /**
     * Retrieves a list of resources by its type
     * getResourceByType
     */
    async getResourceByType(type?: GetResourceByTypeTypeEnum): Promise<Array<Resource>> {
        const response = await this.getResourceByTypeRaw({ type: type });
        return await response.value();
    }

    /**
     * Updates a resource
     * updateResource
     */
    async updateResourceRaw(requestParameters: UpdateResourceRequest): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateResource.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/resources/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResourceToJSON(requestParameters.resource),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * Updates a resource
     * updateResource
     */
    async updateResource(id: number, resource?: Resource): Promise<Resource> {
        const response = await this.updateResourceRaw({ id: id, resource: resource });
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetResourceByTypeTypeEnum {
    BACKGROUND = 'BACKGROUND',
    ELEMENT = 'ELEMENT'
}
