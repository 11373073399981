import { User, UserApi } from "../client";
import { Api, ApiClient } from "./ApiClient";

export default class UserRepository {

    static getApiClient() {
        return ApiClient.getClient(Api.UserApi) as UserApi
    }

    static async getAllUsers(): Promise<User[] | undefined> {
		const api = this.getApiClient();
		var result;
		try {
			result = await api.getAllUsers();
		} catch (error) {
			console.log(error);
		}

		return result;
	}

	static async getUserById(id: number): Promise<User | undefined> {
		const UserApi = this.getApiClient();
		var user;
		try {
			user = await UserApi.getUserById(id);
		} catch (error) {
			console.log(error);
		}

		return user;
	}

	static async updateUser(id: number, user: User): Promise<User | undefined> {
		const api = this.getApiClient();
		var result;
		try {
			result = await api.updateUserById(id, user);
		} catch (error) {
			console.log(error);
			throw Error(error as string);
		}

		return result;
	}

	static async createUser(user: User): Promise<User | undefined> {
		const api = this.getApiClient();
		var result;
		try {
			result = await api.createUser(user);
		} catch (error) {
			console.log(error);
			throw Error(error as string);
		}

		return result;
	}

	static async deleteUser(id: number): Promise<void> {
		const api = this.getApiClient();
		var result;
		try {
			result = await api.deleteUserById(id);
		} catch (error) {
			console.log(error);
			throw Error(error as string);
		}

		return result;
	}

}