import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@mui/material";
import { Button } from "@movicoders/movicoders-components";
import { useTranslation } from "react-i18next";
import { Order } from "../../../client";
import { useManageOrdersStyles } from "./styles/styles";
import OwnColors from "../../../constants/OwnColors";

type TOrderDialog = {
    order?: Order
    open: boolean
    setOpen: (value: boolean) => void
}
const OrderDialog = ({ order, open, setOpen }: TOrderDialog) => {
    const { t } = useTranslation();
    const classes = useManageOrdersStyles();

    const handleClose = () => {
        setOpen(false);
    }

    const buildDate = () => {
        var date = new Date(order?.dateTimestamp!);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`
    }

    return (
        <Dialog fullWidth={false} maxWidth="xs" open={open} onClose={handleClose}>
            <DialogTitle className={classes.dialogTitle}>{`${t("manageOrdersView.orderDialog.title")} ${order?.id}`}</DialogTitle>
            <DialogContent>
                <Grid item container spacing={2}>
                    <Grid item xs={12}>
                        <Typography style={{ fontWeight: "bold" }} display="inline">{t("manageOrdersView.orderDialog.status")}</Typography>
                        <Typography style={{ fontWeight: "bold" }} display="inline" color="textSecondary">{t(`manageOrdersView.status.${order?.status}`)}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ fontWeight: "bold" }} display="inline">{t("manageOrdersView.orderDialog.date")}</Typography>
                        <Typography style={{ fontWeight: "bold" }} display="inline" color="textSecondary">{buildDate()}</Typography>
                    </Grid>
                    {order?.userId && <Grid item xs={12}>
                        <Typography style={{ fontWeight: "bold" }} display="inline">{t("manageOrdersView.orderDialog.user")}</Typography>
                        <Typography style={{ fontWeight: "bold" }} display="inline" color="textSecondary">{order?.finalDesign && order?.finalDesign![0].uploader?.toString()}</Typography>
                    </Grid>}
                    <Grid item container spacing={2} xs={12}>
                        <Grid item xs={6}>
                            <img src={order?.finalDesign![0]?.bucketUrl} alt={order?.finalDesign![0]?.fileName} width={150} height={200} />
                        </Grid>
                        <Grid item xs={6}>
                            <img src={order?.finalDesign![1]?.bucketUrl} alt={order?.finalDesign![1]?.fileName} width={150} height={200} />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button text={"cerrar"}
                    onClick={handleClose}
                    style={{ fontWeight: "bold" }}
                    textColor="white !important"
                    hoverColor="white !important"
                    backgroundColor={`${OwnColors.buttonBlack} !important`}
                    hoverBackgroundColor={`${OwnColors.buttonBlackHover}  !important`} />
            </DialogActions>
        </Dialog>
    );
}

export default OrderDialog;