import { Grid, Typography } from "@mui/material";
import { Button } from "@movicoders/movicoders-components";
import { useTranslation } from "react-i18next";
import OwnColors from "../../../../../constants/OwnColors";
import { ResourceViewModel } from "../../../../../viewmodels/ResourceViewModel";
import { useShinpadTemplateStyles } from "../../styles/styles";
import { Resource } from "../../../../../client";
import ImageButton from "../../../../../components/imageButton/ImageButton";
import React from "react";

type TElementsMenu = {
    addImage: (url: string, extension: string) => void
    changeTab: (value: number) => void
}
export const ElementsMenu = React.memo(({ addImage, changeTab }: TElementsMenu) => {
    const { t } = useTranslation();
    const classes = useShinpadTemplateStyles();

    const addElement = (el: Resource) => {
        ResourceViewModel.getInstance().transformElement(el).then(res => {
            let url = el.image?.downloadUrl;
            let extension = el.image?.mimeType?.split("/")[1];
            addImage(url ?? "", extension!)
        });
    }

    return (
        <Grid container style={{ padding: "5%", height: "100%" }}>
            <Grid item container xs={12} style={{ height: "fit-content" }} >
                <div className={classes.numberDiv}>3</div>
                <Typography style={{ color: "white", fontSize: "2rem" }}>{t("shinpadTemplateView.leftMenu.icons.elements")}</Typography>
                <Grid item xs={12} style={{ display: "flex", flexWrap: "wrap", columnGap: "5px", rowGap: "5px", margin: "10px 0 10px 0" }}>
                    {ResourceViewModel.getInstance().elements.length > 0 ?
                        ResourceViewModel.getInstance().elements.map((res: Resource) => {
                            let icon = res.icon?.bucketUrl;
                            return (
                                <ImageButton
                                    onClick={() => addElement(res)}
                                    image={icon}
                                    imageName={res.icon?.fileName}
                                    isSelected={false}
                                />
                            );;
                        }) :
                        <Typography style={{ color: "white" }}>{"No hay elementos para añadir"}</Typography>
                    }
                </Grid>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item container xs={12} sm={12} md={6}>
                        <Button
                            text={t("shinpadTemplateView.leftMenu.previous")}
                            onClick={() => { changeTab(2) }}
                            style={{ fontWeight: "bold", width: "100%" }}
                            textColor="white !important"
                            hoverColor="black !important"
                            backgroundColor={`${OwnColors.podoactivaGrey} !important`}
                            hoverBackgroundColor={`white  !important`}
                        />
                    </Grid>
                    <Grid item container xs={12} sm={12} md={6}>
                        <Button
                            text={t("shinpadTemplateView.leftMenu.next")}
                            onClick={() => { changeTab(4) }}
                            style={{ fontWeight: "bold", width: "100%", border: "2px solid white" }}
                            textColor="white !important"
                            hoverColor="white !important"
                            backgroundColor={`${OwnColors.buttonBlack} !important`}
                            hoverBackgroundColor={`${OwnColors.buttonBlackHover}  !important`}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
})