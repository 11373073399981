import { IconButton } from "@movicoders/movicoders-components";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { ApplicationPaths } from "../../../../constants/Application";
import { UserViewModel } from "../../../../viewmodels/UserViewModel";
import {
  AccountCircle,
  Edit,
  ExitToApp,
  Group,
  InsertPhoto,
  ShoppingCart,
} from "@mui/icons-material";

const CustomDrawer = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const handleIconClick = () => {
    if (UserViewModel.getInstance().loggedUser) setOpen(true);
    else history.push("/login");
  };

  const handleLogOut = () => {
    UserViewModel.getInstance().logout();
    setOpen(false);
  };

  return (
    <>
      <IconButton
        icon={<AccountCircle fontSize="large" />}
        color="white"
        backgroundColor="rgba(0 0 0 / 0%)"
        onClick={handleIconClick}
        style={{ float: "right" }}
      />
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <List>
          {UserViewModel.getInstance().loggedUserIsAdmin() && (
            <>
              <ListItem
                button
                onClick={() => history.push(ApplicationPaths.MANAGE_USERS)}
              >
                <ListItemIcon>
                  <Group />
                </ListItemIcon>
                <ListItemText primary={"Usuarios"} />
              </ListItem>
              <ListItem
                button
                onClick={() => history.push(ApplicationPaths.MANAGE_RESOURCES)}
              >
                <ListItemIcon>
                  <InsertPhoto />
                </ListItemIcon>
                <ListItemText primary={"Recursos"} />
              </ListItem>
            </>
          )}
          <ListItem
            button
            onClick={() => history.push(ApplicationPaths.ORDERS)}
          >
            <ListItemIcon>
              <ShoppingCart />
            </ListItemIcon>
            <ListItemText primary={"Pedidos"} />
          </ListItem>
          <ListItem
            button
            onClick={() => history.push(ApplicationPaths.USER_EDIT)}
          >
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText primary={"Editar perfil"} />
          </ListItem>
          <ListItem button onClick={handleLogOut}>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText primary={"Cerrar Sesión"} />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default CustomDrawer;
