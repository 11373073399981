const OwnColors = {

    podoactivaBlack: "#121212",
    podoactivaBlack2: "#181818",
    podoactivaBlack3: "#333232",
    podoactivaGrey: "#282828",
    podoactivaGrey2: "#303030",
    buttonGreen: "#9bd60d",
    buttonBlack: "#000",
    buttonGreenHover: "#6f9d00",
    buttonBlackHover: "#645e6e",
    defaultBackground: "#eeeeee",
    backgroundColors: ["#546e7a", "#757575", "#6d4c41", "#f4511e", "#fb8c00", "#ffb300",
                       "#fdd835", "#c0ca33", "#a0ce4e", "#7cb342", "#43a047", "#00897b", "#00acc1",
                       "#3fc7ba", "#039be5", "#3949ab", "#5e35b1", "#8e24aa", "#d81b60", "#3a3a3a"]
}

export default OwnColors;