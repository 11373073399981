import Konva from "konva";
import { Fragment, useRef } from "react";
import { Rect, Image } from "react-konva";
import useImage from "use-image";

type TBackground = {
    color: string
    onSelect: () => void
    stageWidth: number
    stageHeight: number
}
const Background = ({ color, onSelect, stageWidth, stageHeight }: TBackground) => {
    const [img] = useImage(color);
    const rectRef = useRef<Konva.Rect>(null);

    return (
        <Fragment>
            {color.startsWith("#") ?
                <Rect
                    width={stageWidth}
                    height={stageHeight}
                    fill={`${color}`}
                    ref={rectRef}
                    x={0}
                    y={0}
                    onClick={onSelect}
                    onTap={onSelect}
                /> :
                <Image
                    x={0}
                    y={0}
                    width={stageWidth}
                    height={stageHeight}
                    image={img!}
                    draggable={false}
                    onClick={onSelect}
                    onTap={onSelect}
                />
            }
        </Fragment>
    );
}

export default Background;