import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Tabs, Tab } from "@mui/material";
import { Button, IconButton } from "@movicoders/movicoders-components";
import OwnColors from "../../../../../constants/OwnColors";
import { useShinpadTemplateStyles } from "../../styles/styles";
import IImage from "../../../../../interfaces/IImage";
import IText from "../../../../../interfaces/IText";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

type TLayersMenu = {
  leftItems: (IImage | IText)[];
  rightItems: (IImage | IText)[];
  changeTab: (value: number) => void;
  setLeftItems: (value: (IImage | IText)[]) => void;
  setRightItems: (value: (IImage | IText)[]) => void;
  handleBuy: () => void;
};
export const LayersMenu = ({
  leftItems,
  rightItems,
  changeTab,
  setLeftItems,
  setRightItems,
  handleBuy,
}: TLayersMenu) => {
  const { t } = useTranslation();
  const classes = useShinpadTemplateStyles();
  const [tab, setTab] = useState<number>(1);

  const handleChange = (e: any, value: any) => {
    setTab(value);
  };

  const renderItem = (item: IImage | IText) => {
    let isImage = item.id.startsWith("image");
    return (
      <Grid item xs={12} className={classes.layersLines}>
        <Grid
          item
          xs={9}
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflowX: "hidden",
          }}
        >
          {isImage ? (
            <>
              <img
                src={(item as IImage).src}
                alt={item.id}
                height={50}
                width={50}
              />
            </>
          ) : (
            <>
              <Typography display="inline" style={{ color: "white" }}>
                {(item as IText).text}
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={3}>
          <IconButton
            icon={<ArrowDropDown />}
            backgroundColor="transparent"
            onClick={() => handleMoveDown(item)}
          />
          <IconButton
            icon={<ArrowDropUp />}
            backgroundColor="transparent"
            onClick={() => handleMoveUp(item)}
          />
        </Grid>
      </Grid>
    );
  };

  const noItems = () => {
    return (
      <Grid item xs={12}>
        <Typography style={{ color: "white" }}>
          {t("shinpadTemplateView.leftMenu.layers.noLayers")}
        </Typography>
      </Grid>
    );
  };

  /**
   * Moves a item one layer up
   * @param item
   * @returns
   */
  const handleMoveUp = (item: IImage | IText) => {
    let index = leftItems.indexOf(item);
    const itemsSupp =
      index === -1
        ? rightItems.slice(parseInt(item.id!))
        : leftItems.slice(parseInt(item.id!));
    const itemSupp = itemsSupp.find((i) => i.id === item.id);
    let initialIndex = itemsSupp.indexOf(itemSupp!);
    if (initialIndex === itemsSupp.length - 1) return;
    let finalIndex = initialIndex + 1;
    itemsSupp.splice(finalIndex, 0, itemsSupp.splice(initialIndex, 1)[0]);
    index === -1 ? setRightItems(itemsSupp) : setLeftItems(itemsSupp);
  };

  /**
   * Moves a item one layer down
   * @param item
   * @returns
   */
  const handleMoveDown = (item: IImage | IText) => {
    let index = leftItems.indexOf(item);
    const itemsSupp =
      index === -1
        ? rightItems.slice(parseInt(item.id!))
        : leftItems.slice(parseInt(item.id!));
    const itemSupp = itemsSupp.find((i) => i.id === item.id);
    let initialIndex = itemsSupp.indexOf(itemSupp!);
    if (initialIndex === 0) return;
    let finalIndex = initialIndex - 1;
    itemsSupp.splice(finalIndex, 0, itemsSupp.splice(initialIndex, 1)[0]);
    index === -1 ? setRightItems(itemsSupp) : setLeftItems(itemsSupp);
  };

  return (
    <Grid container style={{ padding: "5%", height: "100%" }}>
      <Grid item container xs={12} style={{ height: "fit-content" }}>
        <div className={classes.numberDiv}>6</div>
        <Typography style={{ color: "white", fontSize: "2rem" }}>
          {t("shinpadTemplateView.leftMenu.layers")}
        </Typography>
        <Grid item xs={12} style={{ marginTop: 5 }}>
          <Tabs
            value={tab}
            centered
            onChange={handleChange}
            className={classes.tabList}
          >
            <Tab
              value={1}
              label={t("shinpadTemplateView.leftMenu.layers.left")}
              className={classes.layerTab}
            />
            <Tab
              value={2}
              label={t("shinpadTemplateView.leftMenu.layers.right")}
              className={classes.layerTab}
            />
          </Tabs>
          <Grid item xs={12} className={classes.layersContent}>
            {tab === 1 &&
              (leftItems.length > 0
                ? leftItems.slice(0).reverse().map(renderItem)
                : noItems())}
            {tab === 2 &&
              (rightItems.length > 0
                ? rightItems.slice(0).reverse().map(renderItem)
                : noItems())}
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 2, md: 3 }} style={{ marginTop: 5 }}>
          <Grid item container xs={12} sm={12} md={6}>
            <Button
              text={t("shinpadTemplateView.leftMenu.previous")}
              onClick={() => {
                changeTab(5);
              }}
              style={{ fontWeight: "bold", width: "100%" }}
              textColor="white !important"
              hoverColor="black !important"
              backgroundColor={`${OwnColors.podoactivaGrey} !important`}
              hoverBackgroundColor={`white  !important`}
            />
          </Grid>
          <Grid item container xs={12} sm={12} md={6}>
            <Button
              text="Comprar"
              onClick={handleBuy}
              style={{ fontWeight: "bold", width: "100%", border: "2px solid white" }}
              textColor="white !important"
              hoverColor="white !important"
              backgroundColor={`${OwnColors.buttonBlack} !important`}
              hoverBackgroundColor={`${OwnColors.buttonBlackHover}  !important`}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
