import { Alert, Snackbar } from "@mui/material";
import { SyntheticEvent } from "react";

export interface ISnackBar {
    open: boolean,
    autoHideDuration?: number;
    message: string;
    severity: "error" | "info" | "success" | "warning"
    onClose: (value: boolean) => void,
}

export default function SnackBar(props: ISnackBar) {

    const handleCloseSnackBar = (event?: Event | SyntheticEvent<any, Event>, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        props.onClose(false);
    }

    return (
        <span>
            <Snackbar open={props.open} autoHideDuration={props.autoHideDuration !== undefined ? props.autoHideDuration : 3000} onClose={handleCloseSnackBar} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                <Alert onClose={handleCloseSnackBar} severity={props.severity} variant="filled">
                    {props.message}
                </Alert>
            </Snackbar>
        </span>
    )
}