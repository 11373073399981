import { Button, TextField } from "@movicoders/movicoders-components";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import OwnColors from "../../../../../constants/OwnColors";
import { useShinpadTemplateStyles } from "../../styles/styles";

type TTextMenu = {
    addText: (value: string) => void
    changeTab: (value: number) => void
}
export const TextMenu = ({ addText, changeTab }: TTextMenu) => {
    const { t } = useTranslation();
    const classes = useShinpadTemplateStyles();
    const [text, setText] = useState("");

    return (
        <Grid container style={{ padding: "5%", height: "100%" }}>
            <Grid item container xs={12} style={{ height: "fit-content" }} >
                <div className={classes.numberDiv}>5</div>
                <Typography style={{ color: "white", fontSize: "2rem" }}>{t("shinpadTemplateView.leftMenu.addText")}</Typography>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                    <TextField
                        variant={"outlined"}
                        onChange={(e: any) => { setText(e.target.value) }}
                        classTextField={classes.textField}
                    />
                    <Button
                        text={t("shinpadTemplateView.leftMenu.addText")}
                        onClick={() => addText(text)}
                        style={{ fontWeight: "bold", width: "100%", margin: "10px 0 10px 0", border: "2px solid white" }}
                        textColor="white !important"
                        hoverColor="white !important"
                        backgroundColor={`${OwnColors.buttonBlack} !important`}
                        hoverBackgroundColor={`${OwnColors.buttonBlackHover}  !important`}
                    />
                </Grid>
                <Grid container spacing={{ xs: 2, md: 3 }} style={{ marginTop: 5 }}>
                <Grid item container xs={12} sm={12} md={6}>
                        <Button
                            text={t("shinpadTemplateView.leftMenu.previous")}
                            onClick={() => { changeTab(4) }}
                            style={{ fontWeight: "bold", width: "100%" }}
                            textColor="white !important"
                            hoverColor="black !important"
                            backgroundColor={`${OwnColors.podoactivaGrey} !important`}
                            hoverBackgroundColor={`white  !important`}
                        />
                    </Grid>
                    <Grid item container xs={12} sm={12} md={6}>
                        <Button
                            text={t("shinpadTemplateView.leftMenu.next")}
                            onClick={() => { changeTab(6) }}
                            style={{ fontWeight: "bold", width: "100%", border: "2px solid white" }}
                            textColor="white !important"
                            hoverColor="white !important"
                            backgroundColor={`${OwnColors.buttonBlack} !important`}
                            hoverBackgroundColor={`${OwnColors.buttonBlackHover}  !important`}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );

}