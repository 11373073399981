import { Tabs, Tab, useMediaQuery } from "@mui/material";
import productIconOff from "../../../../assets/images/submenu_1_off.svg";
import backgroundIconOff from "../../../../assets/images/submenu_2_off.svg";
import elementsIconOff from "../../../../assets/images/submenu_3_off.svg";
import imageIconOff from "../../../../assets/images/submenu_4_off.svg";
import textIconOff from "../../../../assets/images/submenu_5_off.svg";
import layerIconOff from "../../../../assets/images/submenu_6_off.svg";
import OwnColors from "../../../../constants/OwnColors";
import { useShinpadTemplateStyles } from "../styles/styles";

type TLeftMenuMobile = {
    setTab: (value: number) => void
    tab: number
}
export const LeftMenuMobile = ({ setTab, tab }: TLeftMenuMobile) => {
    const isDesktop = useMediaQuery('(min-width:850px)');
    const classes = useShinpadTemplateStyles();

    const handleChange = (event: any, newValue: number) => {
        setTab(newValue + 1);
    };

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", backgroundColor: OwnColors.podoactivaBlack, }}>
            <Tabs
                value={tab - 1}
                onChange={handleChange}
                variant="fullWidth"
                TabIndicatorProps={{ style: { display: "none" } }}
                orientation="vertical"
                style={{ height: "100%", alignContent: "space-around", width: isDesktop? "50%": "100%" }}
                centered={true}>
                <Tab
                    icon={<img alt="menu-1" src={productIconOff} className={classes.menuImage} />}
                    style={{
                        border: tab - 1 === 0 ? "3px solid white" : "none",
                        minWidth: "auto",
                        width: "80%",
                        borderRadius: "8px",
                        margin: "6px 6px",
                        fontWeight: "bold",
                        paddingTop: "80%"
                    }} />
                <Tab
                    icon={<img alt="menu-2" src={backgroundIconOff} className={classes.menuImage} />}
                    style={{
                        border: tab - 1 === 1 ? "3px solid white" : "none",
                        minWidth: "auto",
                        borderRadius: "8px",
                        margin: "6px 6px",
                        fontWeight: "bold",
                        paddingTop: "80%",
                        width: "80%",
                    }} />
                <Tab
                    icon={<img alt="menu-3" src={elementsIconOff} className={classes.menuImage} />}
                    style={{
                        border: tab - 1 === 2 ? "3px solid white" : "none",
                        minWidth: "auto",
                        borderRadius: "8px",
                        margin: "6px 6px",
                        fontWeight: "bold",
                        paddingTop: "80%",
                        width: "80%",
                    }} />
                <Tab
                    icon={<img alt="menu-4" src={imageIconOff} className={classes.menuImage} />}
                    style={{
                        border: tab - 1 === 3 ? "3px solid white" : "none",
                        minWidth: "auto",
                        borderRadius: "8px",
                        margin: "6px 6px",
                        fontWeight: "bold",
                        paddingTop: "80%",
                        width: "80%",
                    }} />
                <Tab
                    icon={<img alt="menu-5" src={textIconOff} className={classes.menuImage} />}
                    style={{
                        border: tab - 1 === 4 ? "3px solid white" : "none",
                        minWidth: "auto",
                        borderRadius: "8px",
                        margin: "6px 6px",
                        fontWeight: "bold",
                        paddingTop: "80%",
                        width: "80%",
                    }} />
                <Tab
                    icon={<img alt="menu-6" src={layerIconOff} className={classes.menuImage} />}
                    style={{
                        border: tab - 1 === 5 ? "3px solid white" : "none",
                        minWidth: "auto",
                        borderRadius: "8px",
                        margin: "6px 6px",
                        fontWeight: "bold",
                        paddingTop: "80%",
                        width: "80%",
                    }} />
            </Tabs>
        </div>
    );
}