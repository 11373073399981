import { makeAutoObservable } from "mobx";
import { Credentials, LoginResponse, User } from "../client";
import { SessionStore } from "../data/SessionStore";
import LoginRepository from "../repositories/LoginRepository";
import { UserViewModel } from "./UserViewModel";

export class LoginViewModel {
    private static instance: LoginViewModel | null = null;

	static getInstance(): LoginViewModel {
		if (!LoginViewModel.instance) {
			LoginViewModel.instance = new LoginViewModel();
		}
		return LoginViewModel.instance;
	}

    token: string;

    private constructor() {
		makeAutoObservable(this);
        this.token = '';
	}

    public login(credentials: Credentials): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if(credentials && credentials.username && credentials.username.length > 0 && credentials?.password && credentials?.password?.length > 0){
                return LoginRepository.login(credentials).then((res: LoginResponse | undefined) => {
                    if(res?.token && res.userId){
                        this.setToken(res.token);
                        SessionStore.setToken(res.token);
                        UserViewModel.getInstance().getUserById(res.userId)
						.then((user: User | undefined) => {
							if(user){
								UserViewModel.getInstance().setLoggedUser(user);
								SessionStore.setLocalStorageUser(user);
								resolve(true);
							}else{
								resolve(false);
							}
						}).catch(() => resolve(false))
                        resolve(true);
                    }
                }).catch(() => resolve(false))
            }else{
                resolve(false);
            }
        });
    }

    public logout() {
        SessionStore.setToken("");
        UserViewModel.getInstance().setLoggedUser(undefined);
        SessionStore.setCurrentPath('/home');
		SessionStore.setLocalStorageUser(undefined);
    }

    public setToken(token: string) {
		this.token = token;
	}

}