import { CircularProgress } from "@mui/material";

const LoadingCircle = () => {

    return(
        <div style={{width: '100%', height: '500px', display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
            <CircularProgress />
        </div>
    );

}

export default LoadingCircle;