import { generateZip, importZip } from "../../../../../helpers/DownloadHelper"
import IImage from "../../../../../interfaces/IImage"
import IText from "../../../../../interfaces/IText"
import { useTranslation } from "react-i18next";
import OwnColors from "../../../../../constants/OwnColors";

import { Grid, Button as MuiButton, MenuItem, Menu } from "@mui/material";
import { Button } from "@movicoders/movicoders-components";
import { useShinpadTemplateStyles } from "../../styles/styles";
import { useState } from "react";

type TDefaultOptionBar = {

    items: (IImage | IText)[],
    items2: (IImage | IText)[],
    setItems: (itms: (IImage | IText)[]) => void,
    setItems2: (itms: (IImage | IText)[]) => void

    background: string[]
    setBackground: (bckColor: string[]) => void,

    setItemId: (value: number) => void
    leftActive: boolean,
    handleOpenSnackbar: (text: string) => void,
    handleDownloadImage: () => void
    setOpenDelete: (value: boolean) => void
}

const DefaultOptionBar = ({ items, items2, background, setItems, setItems2, setBackground, setItemId, leftActive, handleOpenSnackbar, handleDownloadImage, setOpenDelete }: TDefaultOptionBar) => {
    const { t } = useTranslation();
    const classes = useShinpadTemplateStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDownloadZip = () => {
        generateZip(items!, items2!, background!);
        handleClose()
    }

    const downloadImage = () => {
        handleDownloadImage();
        handleClose();
    }

    const handleImportZip = (file?: File | null) => {
        if (file !== null && file !== undefined) {
            if (file.name.split('.').pop() === 'zip') {
                importZip(file).then(res => {
                    if (res) {
                        setItems!(res[0] as (IImage | IText)[]);
                        setItems2!(res[1] as (IImage | IText)[]);
                        setBackground!(res[2] as string[]);
                        setItemId((res[3] as number) + 1);
                    }
                }).catch((error) => {
                    handleOpenSnackbar("import.error");
                });
            } else {
                handleOpenSnackbar("import.notZip");
            }
        }
    }

    const handleDuplicate = () => {
        leftActive ? (setItems2(JSON.parse(JSON.stringify(items)))) : (setItems(JSON.parse(JSON.stringify(items2)))) //Correcion de la posicion y concrecion de funcionalidad
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <Grid container item xs={12} justifyContent="space-between">
            <Grid item direction="row">
                <Button
                    text={t("shinpadTemplateView.toolbar.delete")}
                    onClick={() => setOpenDelete(true)}
                    style={{ fontWeight: "bold", marginRight: "20px", border: "2px solid white" }}
                    textColor="white !important"
                    hoverColor="white !important"
                    backgroundColor={`${OwnColors.buttonBlack} !important`}
                    hoverBackgroundColor={`${OwnColors.buttonBlackHover}  !important`}
                />
                <Button
                    text={t("shinpadTemplateView.toolbar.duplicate")}
                    onClick={handleDuplicate}
                    style={{ fontWeight: "bold", border: "2px solid white" }}
                    textColor="white !important"
                    hoverColor="white !important"
                    backgroundColor={`${OwnColors.buttonBlack} !important`}
                    hoverBackgroundColor={`${OwnColors.buttonBlackHover}  !important`}
                />
            </Grid>
            <Grid item direction="row">
                <MuiButton
                    onClick={handleClick}
                    style={{ fontWeight: "bold", marginRight: "20px", color: "white", backgroundColor: OwnColors.buttonBlack, border: "2px solid white" }}
                >
                    {t("shinpadTemplateView.toolbar.download")}
                </MuiButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={handleDownloadZip}>{t('download.zip')}</MenuItem>
                    <MenuItem onClick={downloadImage}>{t('download.image')}</MenuItem>
                </Menu>
                <MuiButton
                    variant="contained"
                    component="label"
                    className={classes.importButton}
                    style={{ marginRight: "20px", border: "2px solid white" }}
                    disableRipple
                >
                    {t("shinpadTemplateView.toolbar.import")}
                    <input
                        type="file"
                        hidden
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleImportZip(e.target.files?.item(0));
                        }}
                    />
                </MuiButton>
            </Grid>
        </Grid>
    );

}

export default DefaultOptionBar;