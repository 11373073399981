import { Grid } from "@mui/material";
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Button, TypographyTheme } from "@movicoders/movicoders-components";
import { useEffect, useState } from "react";
import { OrdersViewmodel } from "../../viewmodels/OrdersViewModel";
import { checkUserIsAdmin } from "../../helpers/Utils";
import { UserViewModel } from "../../viewmodels/UserViewModel";
import { Image, Order } from "../../client";
import { useTranslation } from "react-i18next";
import OrderDialog from "./components/OrderDialog";
import StatusDialog from "./components/StatusDialog";
import { useManageOrdersStyles } from "./components/styles/styles";
import SnackBar from "../../components/snackbar/Snackbar";
import { IconButton } from "@mui/material";
import { Download, DownloadOutlined } from "@mui/icons-material";
import { downloadURI, generateZip } from "../../helpers/DownloadHelper";
import OwnColors from "../../constants/OwnColors";
import { ImageViewModel } from "../../viewmodels/ImageViewModel";

const ManageOrdersView = () => {
    const { t } = useTranslation();
    const classes = useManageOrdersStyles();
    const [orders, setOrders] = useState<Array<Order>>([]);
    const [openInfo, setOpenInfo] = useState(false);
    const [openStatus, setOpenStatus] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarError, setSnackbarError] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [selectedOrder, setSeletectOrder] = useState<Order | undefined>()

    useEffect(() => {
        loadData();
    }, [])

    const loadData = () => {
        let user = UserViewModel.getInstance().loggedUser!
        if (checkUserIsAdmin(user)) {
            OrdersViewmodel.getInstance().getAllOrders().then(res => setOrders(res ?? []));
        } else {
            OrdersViewmodel.getInstance().getOrdersFilteredByUser(user.id!).then(res => setOrders(res ?? []));
        }
    }

    const buildDate = (timeStamp: number) => {
        var date = new Date(timeStamp);
        let minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${minutes}`
    }

    const handleOpenSnackbar = (open: boolean, error: boolean, message: string) => {
        setSnackbarMessage(message);
        setSnackbarError(error);
        setOpenSnackbar(open);
    }

    const handleDownloadImage = async (id: number, name: string) => {
        ImageViewModel.getInstance().downloadImageById(id).then((base64) => {
            downloadURI("data:image/png;base64," + base64, name)

        })
    }

    const columns: GridColDef[] = [
        {
            field: "id",
            type: 'string',
            sortable: true,
            disableColumnMenu: false,
            headerAlign: 'center',
            align: 'center',
            headerName: "Id",
            width: 120
        },
        {
            field: "status",
            type: 'string',
            sortable: true,
            disableColumnMenu: false,
            headerAlign: 'center',
            align: 'center',
            headerName: t("manageOrdersView.title.table.status"),
            width: 200,
            renderCell: (params) => {
                let status = params.value
                return t(`manageOrdersView.status.${status}`)
            }
        },
        {
            field: "dateTimestamp",
            type: 'number',
            sortable: true,
            disableColumnMenu: false,
            headerAlign: 'center',
            align: 'center',
            headerName: t("manageOrdersView.title.table.date"),
            width: 200,
            renderCell: (params) => {
                let date = buildDate(params.value)
                return date
            }
        },
        {
            field: "finalDesign",
            type: 'string',
            sortable: true,
            disableColumnMenu: false,
            headerAlign: 'center',
            align: 'center',
            headerName: t("manageOrdersView.title.table.design"),
            width: 200,
            renderCell: (params) => {
                let image = params.value as Image[]
                let id = image[0].fileName!.split(".")[0].split("-")[2]
                const handleClick = () => {
                    handleDownloadImage(image[0].id!, image[0].fileName!)
                    handleDownloadImage(image[1].id!, image[1].fileName!)
                }
                return (
                    <>
                        <IconButton onClick={() => handleClick()}>
                            <DownloadOutlined />
                        </IconButton>
                        <p>{id}</p>
                    </>
                );
            }
        },
        {
            field: "userId",
            type: 'number',
            sortable: true,
            disableColumnMenu: false,
            headerAlign: 'center',
            align: 'center',
            headerName: t("manageOrdersView.title.table.user"),
            width: 180,
            renderCell: (params) => {
                let name = params.value? params.row.finalDesign[0].uploader: params.value
                return(
                    <p>{name }</p>
                )
            }
        },
        {
            field: 'buttons',
            headerName: " ",
            width: 200,
            filterable: false,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {

                const handleClickButton = () => {
                    setOpenStatus(true);
                    setSeletectOrder(JSON.parse(JSON.stringify(params.row)));
                }

                return (
                    <>
                        <Button text={"Cambiar Estado"}
                            onClick={handleClickButton}
                            style={{ fontWeight: "bold" }}
                            textColor="white !important"
                            hoverColor="white !important"
                            backgroundColor={`${OwnColors.buttonBlack} !important`}
                            hoverBackgroundColor={`${OwnColors.buttonBlackHover}  !important`} />
                    </>
                );
            }
        },
        {
            field: 'descargar',
            headerName: "Descargar",
            width: 100,
            filterable: false,
            disableColumnMenu: true,
            sortable: false,
            align: 'center',
            flex: 1,
            renderCell: (params) => {

                const handleDownload = () => {
                    let structure = JSON.parse(params.row.components.structure);
                    let background = params.row.components.background
                    let images = params.row.components.images;
                    try {
                        generateZip(structure[0], structure[1], structure[2], images, background)
                    } catch (error) {
                        console.log(error);
                    }
                }

                return (
                    <>
                        <IconButton onClick={handleDownload}>
                            <Download />
                        </IconButton>
                    </>
                );
            }
        }
    ]

    return (
        <Grid container className={classes.parentContainer}>
            <Grid item xs={8}>
                <Grid item xs={12} container justifyContent="space-between">
                    <TypographyTheme text={t("manageOrdersView.title")} variant="h3" />
                </Grid>
                <DataGrid
                    rows={orders}
                    columns={columns}
                    checkboxSelection={false}
                    onRowDoubleClick={(params) => {
                        setSeletectOrder(params.row);
                        setOpenInfo(true);
                    }}
                    autoHeight
                />
                <OrderDialog order={selectedOrder} open={openInfo} setOpen={setOpenInfo} />
                <StatusDialog order={selectedOrder} open={openStatus} setOpen={setOpenStatus} loadData={loadData} openSnackbar={handleOpenSnackbar} />
                <SnackBar open={openSnackbar} autoHideDuration={2000} severity={snackbarError ? "error" : "success"} message={snackbarMessage} onClose={(value: boolean) => setOpenSnackbar(value)} />
            </Grid>
        </Grid>
    );
}

export default ManageOrdersView;