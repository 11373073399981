import React from 'react';
import { Redirect, Route, RouteProps } from "react-router-dom";
import { ApplicationPaths, Application_PROFILE } from "../../constants/Application";
import { SessionStore } from '../../data/SessionStore';
import manageSession, { reloadData } from './SessionManager';
import { LoginViewModel } from '../../viewmodels/LoginViewModel';
import * as ViewList from './ViewList';

export interface ProtectedRouteProps extends RouteProps {
    requiresAuth: boolean;
    allowedRoles: Application_PROFILE[] | undefined;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {

    const [redirectPath, setRedirectPath] = React.useState('');
    const [showLoading, setShowLoading] = React.useState(true);

    if (props.path) SessionStore.setCurrentPath(props.location?.pathname as string)

    React.useEffect(() => {
        let { isAllowed, requiresLoad, isLogged, tokenExpired } = manageSession(props.requiresAuth, props.allowedRoles || []);
        if (tokenExpired) {
            LoginViewModel.getInstance().logout();
            setRedirectPath(ApplicationPaths.LOGIN);
            setShowLoading(false);
        } if (requiresLoad) {
            setShowLoading(true);
            reloadData()
                .then(() => {
                    let { isAllowed, requiresLoad, isLogged, tokenExpired } = manageSession(props.requiresAuth, props.allowedRoles || [])
                    if (props.requiresAuth) {
                        if (!isLogged) setRedirectPath(ApplicationPaths.LOGIN);
                        if (isLogged && !isAllowed) setRedirectPath(ApplicationPaths.RESTRICTED_PATH);
                    }
                    setShowLoading(false);
                }).catch((err) => {
                    console.log("Error: " + err)
                    setShowLoading(false)
                });
        } else {
            if (props.requiresAuth) {
                if (!isLogged) setRedirectPath(ApplicationPaths.LOGIN);
                if (isLogged && !isAllowed) setRedirectPath(ApplicationPaths.RESTRICTED_PATH);
            }
            setShowLoading(false);
        }
    }, [])

    return (
        (showLoading && <><Route {...props} component={ViewList.loadingApp} /></>) ||
        (redirectPath && <Route {...props} component={() => <Redirect to={{ pathname: redirectPath }} />} render={undefined} />) ||
        <Route {...props} />
    );
}

export default ProtectedRoute;