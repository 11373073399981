
import { Grid } from "@mui/material";
import DefaultOptionBar from "./variants/DefaultOptionBar"
import TextOptionBar from "./variants/TextOptionBar";
import ImageOptionBar from "./variants/ImageOptionBar";
import IText from "../../../../interfaces/IText";
import IImage from "../../../../interfaces/IImage";
import { useShinpadTemplateStyles } from "../styles/styles";
import { IconButton } from "@mui/material";
import { Delete, Replay } from '@mui/icons-material';



type TEditOptions = {
    item?: IText | IImage,

    items: (IImage | IText)[],
    items2: (IImage | IText)[],
    setItems: (itms: (IImage | IText)[]) => void,
    setItems2: (itms: (IImage | IText)[]) => void

    selectedId: (string | null),
    isTextSelected: boolean
    leftActive: boolean,
    setItemId: (value: number) => void,
    selectImage: (value: string | null) => void
    selectText: (value: boolean) => void
    handleOpenSnackbar: (text: string) => void
    background: string[]
    setBackground: (bckColor: string[]) => void
    handleDownloadImage: () => void
    setOpenDelete: (value: boolean) => void
}

const EditOptions = ({ item, items, items2, setItems, setItems2, selectedId, isTextSelected, leftActive, setItemId, selectImage, selectText, handleOpenSnackbar, background, setBackground, handleDownloadImage,setOpenDelete }: TEditOptions) => {
    const classes = useShinpadTemplateStyles();

    const handleDelete = () => {
        const itms = leftActive ? items.slice() : items2.slice();
        const itemSupp = itms.find(i => i.id === selectedId);
        itms.splice(itms.indexOf(itemSupp!), 1)
        leftActive ? setItems(itms) : setItems2(itms);
        selectImage(null);
        selectText(false);
    }

    const deselect = () => {
        if (selectedId !== null || isTextSelected) {
            selectImage(null);
            selectText(false);
        }
    }

    return (
        <Grid item xs={12} className={classes.optionsDiv}>
            {item !== undefined ?
                <Grid container justify-content="space-between">
                    <Grid container>
                        {item.id.match("^text_") ?
                            <TextOptionBar item={(item as IText)} items={items} items2={items2} setItems={setItems} setItems2={setItems2} selectedId={selectedId} leftActive={leftActive} />
                            :
                            <ImageOptionBar item={(item as IImage)} items={items} items2={items2} setItems={setItems} setItems2={setItems2} selectedId={selectedId} leftActive={leftActive} />

                        }
                        <IconButton onClick={deselect}>
                            <Replay style={{ color: "white", justifyContent: "right" }} />
                        </IconButton>
                        <IconButton onClick={handleDelete}>
                            <Delete style={{ color: "white", justifyContent: "right" }} />
                        </IconButton>
                    </Grid>
                </Grid>

                :
                <DefaultOptionBar handleDownloadImage={handleDownloadImage} handleOpenSnackbar={handleOpenSnackbar} items={items} items2={items2} background={background} setItems={setItems} setItems2={setItems2} setBackground={setBackground} setItemId={setItemId} leftActive={leftActive} setOpenDelete={setOpenDelete}/>
            }
        </Grid>
    )
}

export default EditOptions;