/* tslint:disable */
/* eslint-disable */
/**
 * Podoactiva Backend Api Documentation
 * Podoactiva Backend Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Image
 * @export
 * @interface Image
 */
export interface Image {
    /**
     * 
     * @type {number}
     * @memberof Image
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    uploader?: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    mimeType?: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    downloadUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    bucketUrl?: string;
}

export function ImageFromJSON(json: any): Image {
    return ImageFromJSONTyped(json, false);
}

export function ImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Image {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'uploader': !exists(json, 'uploader') ? undefined : json['uploader'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'mimeType': !exists(json, 'mimeType') ? undefined : json['mimeType'],
        'downloadUrl': !exists(json, 'downloadUrl') ? undefined : json['downloadUrl'],
        'bucketUrl': !exists(json, 'bucketUrl') ? undefined : json['bucketUrl'],
    };
}

export function ImageToJSON(value?: Image | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'uploader': value.uploader,
        'fileName': value.fileName,
        'mimeType': value.mimeType,
        'downloadUrl': value.downloadUrl,
        'bucketUrl': value.bucketUrl,
    };
}


