import { observer } from 'mobx-react';
import { Grid, TextField } from "@mui/material";
import { useEditUserStyles } from './components/styles/styles';
import { useEffect, useState } from 'react';
import { UserViewModel } from '../../viewmodels/UserViewModel';
import { User } from '../../client';
import { Button, TypographyTheme } from '@movicoders/movicoders-components';
import SnackBar from '../../components/snackbar/Snackbar';
import PasswordForm from './components/styles/PasswordForm';
import { useTranslation } from 'react-i18next';
import OwnColors from '../../constants/OwnColors';

const EditUserView = () => {
    const { t } = useTranslation();
    const classes = useEditUserStyles();
    const [user, setUser] = useState<User>({});
    const [usernameError, setUsernameError] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarError, setSnackbarError] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("");

    useEffect(() => {
        let loggedUser = JSON.parse(JSON.stringify(UserViewModel.getInstance().loggedUser));
        loggedUser.password = "";
        if (!loggedUser.address) {
            loggedUser.address = { name:"", surname:"", street: "", number: "", postalCode: undefined, city: "", state: "", country: ""}
        }
        setUser(loggedUser);
    }, []);

    const handleOpenSnackbar = (open: boolean, error: boolean, message: string) => {
        setSnackbarMessage(message);
        setSnackbarError(error);
        setOpenSnackbar(open);
    }

    const handleSaveUser = () => {
        if(user.username == ""){
            setUsernameError(true);
            return
        }
        UserViewModel.getInstance().updateUser(user.id!, user)
            .then(res => {
                handleOpenSnackbar(true, false, t("editUserView.update.success"));
                setUsernameError(false);
            })
            .catch(err => {
                handleOpenSnackbar(true, true, t("editUserView.update.error"));
                setUsernameError(false);
            })
    }

    return (
        <Grid container className={classes.parentContainer} alignContent="flex-start" spacing={2}>
            <Grid item xs={12} container justifyContent="center">
                <TypographyTheme text={'Usuario'} variant="h3" />
            </Grid>
            <Grid item container xs={12} md={6} lg={4} spacing={2}>
                <Grid item xs={12} container justifyContent="center">
                    <TextField
                        id="outlined-basic"
                        label={t("editUserView.form.username")}
                        value={user?.username}
                        onChange={(event) => {
                            user.username = event.target.value;
                            setUser(Object.assign({},user));
                        }}
                        variant="outlined"
                        error={usernameError}
                        helperText={usernameError? t("editUserView.form.username.error"): ""}
                    />
                </Grid>
                <Grid item md={6} sm={6} xs={12} container justifyContent="center">
                    <TextField
                        id="outlined-basic"
                        label={t("editUserView.form.country")}
                        value={user?.address?.country}
                        onChange={(event) => {
                            user.address!.country = event.target.value;
                            setUser(Object.assign({},user));
                        }}
                        variant="outlined"
                    />
                </Grid>
                <Grid item md={6} sm={6} xs={12} container justifyContent="center">
                    <TextField
                        id="outlined-basic"
                        label={t("editUserView.form.state")}
                        value={user?.address?.state}
                        onChange={(event) => {
                            user.address!.state = event.target.value;
                            setUser(Object.assign({},user));
                        }}
                        variant="outlined"
                    />
                </Grid>
                <Grid item md={6} sm={6} xs={12} container justifyContent="center">
                    <TextField
                        id="outlined-basic"
                        label={t("editUserView.form.city")}
                        value={user?.address?.city}
                        onChange={(event) => {
                            user.address!.city = event.target.value;
                            setUser(Object.assign({},user));
                        }}
                        variant="outlined"
                    />
                </Grid>
                <Grid item md={6} sm={6} xs={12} container justifyContent="center">
                    <TextField
                        id="outlined-basic"
                        label={t("editUserView.form.postalCode")}
                        value={user?.address?.postalCode}
                        onChange={(event) => {
                            user.address!.postalCode = parseInt(event.target.value)!;
                            setUser(Object.assign({},user));
                        }}
                        variant="outlined"
                        type="number"
                    />
                </Grid>
                <Grid item md={6} sm={6} xs={12} container justifyContent="center">
                    <TextField
                        id="outlined-basic"
                        label={t("editUserView.form.street")}
                        value={user?.address?.street}
                        onChange={(event) => {
                            user.address!.street = event.target.value;
                            setUser(Object.assign({},user));
                        }}
                        variant="outlined"
                    />
                </Grid>
                <Grid item md={6} sm={6} xs={12} container justifyContent="center">
                    <TextField
                        id="outlined-basic"
                        label={t("editUserView.form.number")}
                        value={user?.address?.number}
                        onChange={(event) => {
                            user.address!.number = event.target.value;
                            setUser(Object.assign({},user));
                        }}
                        variant="outlined"
                    />
                </Grid>
                <Grid item md={6} sm={6} xs={12} container justifyContent="center">
                    <TextField
                        id="outlined-basic"
                        label={t("editUserView.form.name")}
                        value={user?.address?.name}
                        onChange={(event) => {
                            user.address!.name = event.target.value;
                            setUser(Object.assign({},user));
                        }}
                        variant="outlined"
                    />
                </Grid>
                <Grid item md={6} sm={6} xs={12} container justifyContent="center">
                    <TextField
                        id="outlined-basic"
                        label={t("editUserView.form.surname")}
                        value={user?.address?.surname}
                        onChange={(event) => {
                            user.address!.surname = event.target.value;
                            setUser(Object.assign({},user));
                        }}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        text={t("editUserView.form.changePassword")}
                        onClick={() => { setOpenForm(true) }}
                        style={{ width: "100%", fontWeight: "bold" }}
                        textColor="white !important"
                        hoverColor="white !important"
                        backgroundColor={`${OwnColors.buttonBlack} !important`}
                        hoverBackgroundColor={`${OwnColors.buttonBlackHover}  !important`} />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        text={t("editUserView.form.saveChanges")}
                        onClick={handleSaveUser}
                        style={{ width: "100%", fontWeight: "bold" }}
                        textColor="white !important"
                        hoverColor="white !important"
                        backgroundColor={`${OwnColors.buttonBlack} !important`}
                        hoverBackgroundColor={`${OwnColors.buttonBlackHover}  !important`} />
                </Grid>
                <SnackBar open={openSnackbar} message={snackbarMessage} severity={snackbarError ? 'error' : 'success'} onClose={() => { setOpenSnackbar(false) }} />
            </Grid>
            <PasswordForm open={openForm} setOpen={setOpenForm} handleOpenSnackbar={handleOpenSnackbar} />
        </Grid>
    );
}

export default observer(EditUserView);