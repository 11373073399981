import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { ToolbarHeight } from "../../../../constants/Application";

export const useManageUsersStyles = makeStyles((theme: Theme) =>
    createStyles({
        parentContainer: { 
            minHeight: `calc(100vh - ${ToolbarHeight})`, 
            justifyContent: "center", 
            alignItems: "normal" 
        },
        dialogTitle: { 
            backgroundColor: "black", 
            color: "white" 
        },
        dialogRow: { 
            display: "flex", 
            justifyContent: "space-between", 
            alignItems: "center" 
        }
    })
)