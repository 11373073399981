import { GetResourceByTypeTypeEnum, Resource, ResourceApi } from "../client";
import { Api, ApiClient } from "./ApiClient";

export default class ResourceRepository {

    static getApiClient() {
        return ApiClient.getClient(Api.ResourceApi) as ResourceApi
    }

    static async createResource(resource: Resource): Promise<Resource | undefined> {
		const api = this.getApiClient();
		var result;
		try {
			result = await api.createResource(resource);
		} catch (error) {
			console.log(error);
			throw Error(error as string);
		}
		return result;
	}

    static async updateResource(id: number, resource: Resource): Promise<Resource | undefined> {
		const api = this.getApiClient();
		var result;
		try {
			result = await api.updateResource(id, resource);
		} catch (error) {
			console.log(error);
			throw Error(error as string);
		}
		return result;
	}

    static async deleteResource(id: number){
        const api = this.getApiClient();
		var result;
		try {
			result = await api.deleteResourceById(id);
		} catch (error) {
			console.log(error);
			throw Error(error as string);
		}
		return result;
    }

    static async getAllResources(): Promise<Resource[] | undefined> {
        const api = this.getApiClient();
		var result;
		try {
			result = await api.getAllResources();
		} catch (error) {
			console.log(error);
		}
		return result;
    }

    static async getResourceById(id: number): Promise<Resource | undefined> {
        const api = this.getApiClient();
		var result;
		try {
			result = await api.getResourceById(id);
		} catch (error) {
			console.log(error);
		}
		return result;
    }

    static async getResourcesByType(type: GetResourceByTypeTypeEnum): Promise<Resource[] | undefined> {
        const api = this.getApiClient();
		var result;
		try {
			result = await api.getResourceByType(type);
		} catch (error) {
			console.log(error);
		}
		return result;
    }

}