import { Grid, IconButton } from "@mui/material";
import { CheckCircle, Cancel, EditOutlined, DeleteOutlined, AddCircleOutlined } from '@mui/icons-material';
import { useEffect, useState } from "react";
import { ResourceViewModel } from "../../viewmodels/ResourceViewModel";
import { Image, Resource } from "../../client";
import { GridColDef, DataGrid } from '@mui/x-data-grid'
import { useTranslation } from "react-i18next";
import { TypographyTheme } from "@movicoders/movicoders-components";
import ResourcesForm from "./components/ResourcesForm";
import ConfirmDialog from "../../components/confirmDialog/ConfirmDialog";
import { useManageResourcesStyles } from "./components/styles/styles";
import SnackBar from "../../components/snackbar/Snackbar";
import ErrorIcon from '@mui/icons-material/Error';


const ManageResourcesView = () => {
    const { t } = useTranslation();
    const classes = useManageResourcesStyles();
    const [resources, setResources] = useState<Resource[]>([]);
    const [selectedResource, setSelectedResource] = useState<Resource>({});
    const [open, setOpen] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarError, setSnackbarError] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    useEffect(() => {
        loadData();
    }, []);

    const handleCreateResource = () => {
        setSelectedResource({})
        setOpen(true);
    }

    const loadData = () => {
        ResourceViewModel.getInstance().getAllResources().then((res: Resource[] | undefined) => {
            res && setResources(res);
        });
    }

    const handleDeactivate = () => {
        selectedResource.active = false;
        ResourceViewModel.getInstance().updateResource(selectedResource.id!, selectedResource).then(res => {
            loadData();
            handleOpenSnackbar(true, false, t("manageResourcesView.delete.success"))
        }).catch(err => {
            handleOpenSnackbar(true, true, t("manageResourcesView.delete.error"))
        });
    }

    const handleOpenSnackbar = (open: boolean, error: boolean, message: string) => {
        setSnackbarMessage(message);
        setSnackbarError(error);
        setOpenSnackbar(open);
    }

    const columns: GridColDef[] = [
        {
            field: "id",
            type: 'number',
            sortable: true,
            disableColumnMenu: false,
            headerAlign: 'center',
            align: 'center',
            headerName: t("manageResourcesView.table.header.id"),
            width: 200
        },
        {
            field: "icon",
            type: 'string',
            sortable: true,
            disableColumnMenu: false,
            headerAlign: 'center',
            align: 'center',
            headerName: t("manageResourcesView.table.header.image"),
            width: 200,
            renderCell: (params) => {
                let image = (params.value as Image)
                return (
                    image ? <img src={image?.bucketUrl ?? ""} style={{ maxWidth: '100%', maxHeight: '100%' }} /> : <ErrorIcon style={{ margin: "auto" }} />)
            }
        },

        {
            field: "type",
            type: 'string',
            sortable: true,
            disableColumnMenu: false,
            headerAlign: 'center',
            align: 'center',
            headerName: t("manageResourcesView.table.header.type"),
            width: 200,
            renderCell: (params) => {
                return (t(`manageResourcesView.table.header.type.${params.value}`));
            }
        },
        {
            field: "active",
            type: 'boolean',
            sortable: true,
            disableColumnMenu: false,
            headerAlign: 'center',
            align: 'center',
            headerName: t("manageResourcesView.table.header.active"),
            width: 200,
            renderCell: (params) => {
                return (
                    params.row.active ?
                        <CheckCircle style={{ color: '#31ff00' }} /> :
                        <Cancel style={{ color: "#D4374B" }} />
                );
            }
        },
        {
            field: 'buttons',
            headerName: " ",
            width: 120,
            filterable: false,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {

                const handleEditResource = () => {
                    setSelectedResource(JSON.parse(JSON.stringify(params.row)));
                    setOpen(true);
                }

                const handleDeleteResource = () => {
                    setSelectedResource(JSON.parse(JSON.stringify(params.row)));
                    setOpenConfirm(true);
                }

                return (
                    <>
                        <IconButton onClick={handleEditResource}>
                            <EditOutlined />
                        </IconButton>
                        <IconButton onClick={handleDeleteResource}>
                            <DeleteOutlined />
                        </IconButton>
                    </>
                );
            }
        }
    ];

    return (
        <Grid container className={classes.parentContainer}>
            <Grid item xs={10} md={10} lg={6} style={{ height: "80vh" }}>
                <Grid item xs={12} container justifyContent="space-between">
                    <TypographyTheme text={t('manageResourcesView.table.title')} variant="h3" />
                    <IconButton onClick={handleCreateResource}>
                        <AddCircleOutlined fontSize='large' />
                    </IconButton>
                </Grid>
                <DataGrid
                    rows={resources}
                    columns={columns}
                    checkboxSelection={false}
                    pageSize={25}
                />
            </Grid>
            <ResourcesForm resource={selectedResource} open={open} setOpen={setOpen} updateList={loadData} openSnackbar={handleOpenSnackbar} />
            <ConfirmDialog
                open={openConfirm}
                onClose={() => { setOpenConfirm(false) }}
                handleConfirm={() => {
                    handleDeactivate()
                    setOpenConfirm(false)
                }}
                title={t("manageResourcesView.confirmDialog.title")}
                body={`${t("manageResourcesView.confirmDialog.body")}${selectedResource.image?.fileName}?`}
            />
            <SnackBar open={openSnackbar} autoHideDuration={2000} severity={snackbarError ? "error" : "success"} message={snackbarMessage} onClose={(value: boolean) => setOpenSnackbar(value)} />
        </Grid>
    );
}

export default ManageResourcesView;