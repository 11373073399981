import { makeAutoObservable } from "mobx";
import { GetResourceByTypeTypeEnum, Resource } from "../client";
import ResourceRepository from "../repositories/ResourceRepository";
import { ImageViewModel } from "./ImageViewModel";

export class ResourceViewModel {
    private static instance: ResourceViewModel | null = null;

    static getInstance(): ResourceViewModel {
        if (!ResourceViewModel.instance) {
            ResourceViewModel.instance = new ResourceViewModel();
        }
        return ResourceViewModel.instance;
    }

    backgrounds: Resource[];
    elements: Resource[];

    private constructor() {
        makeAutoObservable(this);
        this.backgrounds = [];
        this.elements = [];
    }

    public createResource(resource: Resource) {
        return ResourceRepository.createResource(resource);
    }

    public updateResource(id: number, resource: Resource) {
        return ResourceRepository.updateResource(id, resource);
    }

    public getAllResources() {
        return ResourceRepository.getAllResources();
    }

    public getResourceById(id: number) {
        return ResourceRepository.getResourceById(id);
    }

    public deleteResource(id: number) {
        return ResourceRepository.deleteResource(id);
    }

    public getResourcesByType(type: GetResourceByTypeTypeEnum) {
        return ResourceRepository.getResourcesByType(type);
    }

    public setBackgrounds(resources: Resource[]) {
        this.backgrounds = resources;
    }

    public setElements(resources: Resource[]) {
        this.elements = resources;
    }

    public async transformBackground(background: Resource) {
        try {
            var url = background.image?.downloadUrl
            let b64 = `data:${background.image?.mimeType};base64,` + await ImageViewModel.getInstance().downloadImageById(background.image!.id!)
            const data = await fetch(b64!);
            const blob = await data.blob();
            url = URL.createObjectURL(blob);
            background.image!.downloadUrl = url;
        } catch (e) {
            console.log(e);
        }
    }

    public async transformElement(element: Resource) {
        try {
            var url = element.image?.downloadUrl
            let b64 = `data:${element.image?.mimeType};base64,` + await ImageViewModel.getInstance().downloadImageById(element.image!.id!)
            const data = await fetch(b64!);
            const blob = await data.blob();
            url = URL.createObjectURL(blob);
            element.image!.downloadUrl = url;
        } catch (e) {
            console.log(e);
        }
    }

}