import { makeAutoObservable } from "mobx";
import { User } from "../client";
import { SessionStore } from "../data/SessionStore";
import { checkUserIsAdmin } from "../helpers/Utils";
import UserRepository from "../repositories/UserRepository";

export class UserViewModel {
    private static instance: UserViewModel | null = null;

	static getInstance(): UserViewModel {
		if (!UserViewModel.instance) {
			UserViewModel.instance = new UserViewModel();
		}
		return UserViewModel.instance;
	}

	loggedUser: User | undefined;

	private constructor() {
        makeAutoObservable(this);
    }

    public getAllUsers(): Promise<User[] | undefined> {
        return UserRepository.getAllUsers();
    }

	async getUserById(id: number) {
		return UserRepository.getUserById(id);
	}

	public updateUser(id: number, user: User): Promise<User | undefined> {
		return UserRepository.updateUser(id, user);
	}

	public createUser(user: User): Promise<User | undefined> {
		return UserRepository.createUser(user);
	}

	public deleteUser(id: number) {
		return UserRepository.deleteUser(id);
	}

	public setLoggedUser(user: User | undefined) {
		this.loggedUser = user;
	}

	public loggedUserIsAdmin(){
		if(!this.loggedUser) return false;
		return checkUserIsAdmin(this.loggedUser);
	}

	public logout(){
		UserViewModel.getInstance().setLoggedUser(undefined);
		SessionStore.setToken("");
		SessionStore.setCurrentPath('/home');
		SessionStore.setLocalStorageUser(undefined);
	}

}