import JSZip from "jszip";
import { Image } from "../client";
import IImage from "../interfaces/IImage";
import IText from "../interfaces/IText";
import { ImageViewModel } from "../viewmodels/ImageViewModel";

/**
 * Generates a zip file withdata from 2 arrays
 * @param left - Left shinpad data
 * @param right - Right shinpad data
 * @param background Background color or image url
 */
export const generateZip = async (left: (IImage | IText)[], right: (IImage | IText)[], background?: string[], images?: Image[], backgroundImage?: Image, fromOrder?: boolean) => {
    var json = JSON.stringify([left, right, background]),
        blob = new Blob([json], { type: "octet/stream" }),
        zip = new JSZip();
    zip.folder("images");
    for (let item of left) {
        if ((item as IImage).src) {
            let url = "";
            if (images) {
                url = images.find((value, index) => value.fileName?.localeCompare(item.id))?.downloadUrl ?? "";
            } else {
                url = (item as IImage).src;
            }
            await getBase64FromUrl(url).then((data: string | ArrayBuffer | null) => {
                let base64 = (data as string).substring((data as string).indexOf(",") + 1);
                zip.file(`images/${item.id}.${(item as IImage).extension}`, base64, { base64: true });
            });
        } else if ((item as IImage).id.startsWith("image")) {
            let id = parseInt(item.id.split("_")[1]);
            let image = images![id];
            let base64 = "";
            if (image) {
                base64 = await ImageViewModel.getInstance().downloadImageById(image.id!) ?? ""
                zip.file(`images/${item.id}.${(item as IImage).extension}`, base64, { base64: true });
            }
        }
    }
    for (let item of right) {
        if ((item as IImage).src) {
            let url = "";
            if (images) {
                url = images.find((value, index) => value.fileName?.localeCompare(item.id))?.downloadUrl ?? "";
            } else {
                url = (item as IImage).src;
            }
            await getBase64FromUrl(url).then((data: string | ArrayBuffer | null) => {
                let base64 = (data as string).substring((data as string).indexOf(",") + 1);
                zip.file(`images/${item.id}.${(item as IImage).extension}`, base64, { base64: true });
            });
        }
    }
    if (background && !background[0].startsWith("#")) {
        await getBase64FromUrl(background![0], backgroundImage).then((data: string | ArrayBuffer | null) => {
            let base64 = (data as string).substring((data as string).indexOf(",") + 1);
            zip.file(`images/background.${background![1]}`, base64, { base64: true });
        });
    }
    zip.file("structure.json", blob, {});
    zip.generateAsync({ type: "base64" }).then((base64: string) => {
        downloadURI("data:application/zip;base64," + base64, "Espinillera_Personalizada.zip");
    })
}

/**
 * Receives a zip file and returns an array with the content of the template.
 * @param zip 
 * @returns [ (IImage | IText)[], (IImage | IText)[], string ]
 */
export const importZip = async (zip: File) => {
    if (zip) {
        var jszip = new JSZip();
        let files = await jszip.loadAsync(zip, {});
        let json: ((IImage | IText)[] | string[])[] = JSON.parse(await files.files["structure.json"].async("text"));
        await Promise.all(
            (json[0] as (IImage | IText)[]).map(
                async (item) => {
                    if ((item as IImage).src || (item as IImage).id.startsWith("image")) {
                        let base64 = await files.files[`images/${item.id}.${(item as IImage).extension}`]?.async("blob");
                        if(base64)
                            (item as IImage).src = window.webkitURL.createObjectURL(base64);
                    }
                }
            )
        );
        await Promise.all(
            (json[1] as (IImage | IText)[]).map(
                async (item) => {
                    if ((item as IImage).src || (item as IImage).id.startsWith("image")) {
                        let base64 = await files.files[`images/${item.id}.${(item as IImage).extension}`]?.async("blob");
                        if(base64)
                            (item as IImage).src = window.webkitURL.createObjectURL(base64);
                    }
                }
            )
        );
        if (json[2] && !(json[2] as string[])[0].startsWith("#")) {
            let base64 = await files.files[`images/background.${(json[2] as string[])[1]}`].async("blob");
            (json[2] as string[])[0] = window.webkitURL.createObjectURL(base64);
        }
        let id1 = parseInt((json[0] as (IImage | IText)[]).at(-1)?.id.split("_").at(-1) ?? "") || 0;
        let id2 = parseInt((json[1] as (IImage | IText)[]).at(-1)?.id.split("_").at(-1) ?? "") || 0;
        return [...json, Math.max(id1, id2)];
    }
}

export const getBase64FromUrl = async (url: string, image?: Image): Promise<(string | ArrayBuffer | null)> => {
    var b64 = "";
    if (image !== undefined) {
        b64 = `data:${image?.mimeType};base64,` + await ImageViewModel.getInstance().downloadImageById(image.id!);
    }
    const data = image !== undefined ? await fetch(b64) : await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
        }
    });
}

/**
 * Downloads the image as png in the local storage
 * @param {String} uri - Image coded in base64
 * @param {String} name - Name for the image file in local storage
 */
export const downloadURI = (uri: string, name: string) => {
    var link = document.createElement('a');
    link.style.display = "none";
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const generateSvg = (width: string, height: string) => {
    var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute("height", height)
    svg.setAttribute("height", width)
    var rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
    rect.setAttribute('x', "5");
    rect.setAttribute('y', "5");
    rect.setAttribute('width', "500");
    rect.setAttribute('height', "500");
    rect.setAttribute('fill', '#95B3D7');
    svg.appendChild(rect);
    let serializer = new XMLSerializer();
    var source = serializer.serializeToString(svg);
    var url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source)
    downloadURI(url, "svgTest.svg");
}