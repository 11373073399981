/* tslint:disable */
/* eslint-disable */
/**
 * Podoactiva Backend Api Documentation
 * Podoactiva Backend Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Component,
    ComponentFromJSON,
    ComponentFromJSONTyped,
    ComponentToJSON,
    Image,
    ImageFromJSON,
    ImageFromJSONTyped,
    ImageToJSON,
} from './';

/**
 * Order
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    status?: OrderStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    dateTimestamp?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    quantity?: number;
    /**
     * 
     * @type {Array<Image>}
     * @memberof Order
     */
    finalDesign?: Array<Image>;
    /**
     * 
     * @type {Address}
     * @memberof Order
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    phoneNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    userId?: number;
    /**
     * 
     * @type {Component}
     * @memberof Order
     */
    components?: Component;
    /**
     * 
     * @type {Image}
     * @memberof Order
     */
    preview3d?: Image;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    extra?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    size?: string;
}

export function OrderFromJSON(json: any): Order {
    return OrderFromJSONTyped(json, false);
}

export function OrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Order {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'dateTimestamp': !exists(json, 'dateTimestamp') ? undefined : json['dateTimestamp'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'finalDesign': !exists(json, 'finalDesign') ? undefined : ((json['finalDesign'] as Array<any>).map(ImageFromJSON)),
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'components': !exists(json, 'components') ? undefined : ComponentFromJSON(json['components']),
        'preview3d': !exists(json, 'preview3d') ? undefined : ImageFromJSON(json['preview3d']),
        'extra': !exists(json, 'extra') ? undefined : json['extra'],
        'size': !exists(json, 'size') ? undefined : json['size'],
    };
}

export function OrderToJSON(value?: Order | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'status': value.status,
        'dateTimestamp': value.dateTimestamp,
        'price': value.price,
        'quantity': value.quantity,
        'finalDesign': value.finalDesign === undefined ? undefined : ((value.finalDesign as Array<any>).map(ImageToJSON)),
        'address': AddressToJSON(value.address),
        'email': value.email,
        'phoneNumber': value.phoneNumber,
        'userId': value.userId,
        'components': ComponentToJSON(value.components),
        'preview3d': ImageToJSON(value.preview3d),
        'extra': value.extra,
        'size': value.size,
    };
}

/**
* @export
* @enum {string}
*/
export enum OrderStatusEnum {
    PENDING = 'PENDING',
    CONFIRMED = 'CONFIRMED',
    CANCELLED = 'CANCELLED',
    FINISHED = 'FINISHED'
}


