import { LargeNumberLike } from "crypto";
import { makeAutoObservable } from "mobx";
import { Order, UpdateOrderByIdStatusEnum } from "../client";
import OrderRepository from "../repositories/OrderRepository";


export class OrdersViewmodel {

    private static instance: OrdersViewmodel | null = null;

	static getInstance(): OrdersViewmodel {
		if (!OrdersViewmodel.instance) {
			OrdersViewmodel.instance = new OrdersViewmodel();
		}
		return OrdersViewmodel.instance;
	}

    orders: Order[]

    private constructor() {
		makeAutoObservable(this);
        this.orders = []
	}

    public createOrder(order: Order) {
        return OrderRepository.createOrder(order);
    }

    public updateOrder(id: number, status: UpdateOrderByIdStatusEnum) {
        return OrderRepository.updateOrder(id, status);
    }

    public deleteOrder(id: number) {
        return OrderRepository.deleteOrder(id);
    }

    public getAllOrders() {
        return OrderRepository.getAllOrders();
    }

    public getOrderById(id: number) {
        return OrderRepository.getOrderById(id);
    }

    public getOrdersFilteredByUser(userId: number) {
        return OrderRepository.getOrdersFilteredByUser(userId);
    }

}