import { User } from "../client";
import { Application_PROFILE } from "../constants/Application";
import { SessionStore } from "../data/SessionStore";

export const checkIfTokenExpired = () => {
    let token = SessionStore.getToken();
    if(token){
        return Date.now() >= (JSON.parse(atob(token.split('.')[1]))).exp * 1000
    }else{
        return false;
    }
}

export const checkUserIsAdmin = (user: User): boolean => {
    return user.profile!.includes(Application_PROFILE.PROFILE_ADMIN);
}