import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

//Import any language file with an alias
import en from '../../assets/locales/en.json';
import es from '../../assets/locales/es.json';

//add all the languages to the bundle
const resources= {
    es: { translation: es },
    en: { translation: en }
};

i18n
    .use(detector) //detect browser language
    .use(Backend) // XHR requests
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        backend: {
            loadPath: "./assets/locales/{{lng}}.json"
        },
        lng: "es",
        fallbackLng: "es",
        keySeparator: false,
        interpolation: {
            escapeValue: false //react already safes from xss
        },
        react: {
            wait: true,
        }
    });

export default i18n;