import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { ToolbarHeight } from "../../../../constants/Application";
import OwnColors from "../../../../constants/OwnColors";

const maxWidth = 700;
const minxWidth = 255;

export const useShinpadTemplateStyles = makeStyles((theme: Theme) =>
    createStyles({
        parentContainer: {
            minHeight: `calc(100vh - ${ToolbarHeight})`,
            overflow: "hidden",
            backgroundColor: OwnColors.podoactivaGrey
        },
        leftMenuContainer: {
            maxHeight: `calc(100vh - ${ToolbarHeight})`,
            minHeight: `calc(100vh - ${ToolbarHeight})`,
            backgroundColor: OwnColors.podoactivaBlack2,
            overflowY: "auto"
        },
        stage: {
            backgroundColor: OwnColors.podoactivaGrey,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        selectedStage:{
            backgroundColor: OwnColors.podoactivaGrey,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid",
            borderColor: OwnColors.buttonBlackHover
        },
        carrouselMainGrid: {
            display: 'flex',
            width: '100%',
            objectFit: 'contain',
            justifyContent: 'center',
            backgroundColor: 'white',
            boxShadow: 'none',
            height: maxWidth,
        },
        carrouselMainGridMobile: {
            display: 'flex',
            width: '100%',
            objectFit: 'contain',
            justifyContent: 'center',
            backgroundColor: 'white',
            boxShadow: 'none',
            height: minxWidth,
        },
        carrouselGrid: {
            display: 'flex',
            maxWidth: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            columnGap: "5px"
        },
        carrouselImageGrid: {
            display: 'flex',
            maxWidth: maxWidth,
            width: '100%',
            maxHeight: maxWidth,
            height: '100%', justifyContent: 'center'
        },
        carrouselImageGridMobile: {
            display: 'flex',
            maxWidth: minxWidth,
            width: '100%',
            maxHeight: minxWidth,
            height: '100%', justifyContent: 'center'
        },
        carrouselImage: {
            width: '100%',
            maxWidth: maxWidth,
            minWidth: minxWidth,
            height: '100%',
            maxHeight: maxWidth,
            minHeight: minxWidth,
            objectFit: 'contain'
        },
        backgroundIcon: {
            backgroundColor: "white",
            borderRadius: "5px !important"
        },
        importButton: { //Adding !important is the only way to keep it effective
            fontWeight: "bold !important",
            backgroundColor: OwnColors.buttonBlack + " !important",
            color: "white !important",
            "&:hover": {
                backgroundColor: OwnColors.buttonBlackHover + " !important",
                color: "white !important"
            }
        },
        textField: {
            display: "flex",
            width: "100%",
            backgroundColor: "white",
            borderRadius: "5px",
            height: "fit-content",
            color: "white"
        },
        optionsDiv: {
            width: "fit-content",
            display: "flex",
            flexDirection: "row",
            backgroundColor: OwnColors.podoactivaBlack2,
            alignItems: "center",
            justifyContent: "center",
            height: "auto",
            minHeight: "50px",
            color: "white !important"
        },
        optionsText: {
            height: "35px",
            minWidth: "10em",
            margin: "5px",
            justifyContent: "center",
            backgroundColor: "none",
            borderRadius: "5px",
            border: "white 2px solid !important",
            outlineColor: "transparent !important",
            '& .MuiInputBase-input': {
                color: "white"
            },
            '& .Mui-focused fieldset': {
                border: "none"
            }
        },
        selectOptionsFont: {
            height: "35px",
            minWidth: "10em",
            margin: "5px",
            justifyContent: "center",
            backgroundColor: "none",
            borderRadius: "5px",
            maxWidth: "5em",
            border: "white 2px solid !important",
            '& .MuiInputBase-input': {
                color: "white"
            },
            '&.Mui-selected': {
                outline: 'none !important'
            },
            '& .Mui-focused fieldset': {
                border: "none"
            }
        },
        fontSelectPopOver: {
            height: 400,
            width: 100
        },
        numberDiv: {
            width: 40,
            height: 40,
            backgroundColor: OwnColors.buttonBlack,
            color: "white",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "3px",
            border: "2px solid white"
        },
        imageDropzone: {
            '&.MuiDropzoneArea-root': {
                border: 'none !important'
            }
        },
        imageParagraphDropzone: {
            padding: '5px'
        },
        menuImage: {
            top: "0",
            left: "0",
            right: "0",
            position: "absolute",
            width: "100%",
            height: "100%"
        },
        tabList: {
            margin: "auto",
            '& .MuiTabs-indicator': {
                backgroundColor: 'rgba(0,0,0,0)',
            },
        },
        layerTab: {
            color: "white !important",
            backgroundColor: OwnColors.podoactivaBlack2,
            borderRadius: "5px 5px 0 0 !important",
            '&.Mui-selected': {
                backgroundColor: OwnColors.podoactivaGrey
            }
        },
        layersContent: {
            backgroundColor: OwnColors.podoactivaGrey,
            borderRadius: 5,
            padding: "15px 0px 15px 15px",
            rowGap: 5,
            display: "grid",
            maxHeight: 350,
            overflowY: "scroll"
        },
        layersLines: { 
            display: "flex", 
            alignItems: "center", 
            justifyContent: "space-between", 
            padding: 15, 
            backgroundColor: OwnColors.podoactivaGrey2, 
            borderRadius: 5 
        }
    })
)