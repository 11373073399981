import { Button, TypographyTheme } from "@movicoders/movicoders-components"
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material"
import { useTranslation } from "react-i18next"

type TDeleteDialog = {
  delete: () => void
  onClose: () => void
  open: boolean
}
export const DeleteDialog = (props: TDeleteDialog) => {
  const { t } = useTranslation();

  return(
    <Dialog open={props.open} onClose={props.onClose} fullWidth>
      <DialogTitle>{t("deleteDialog.title")}</DialogTitle>
      <DialogContent>
        <TypographyTheme text={t("deleteDialog.text")}/>
      </DialogContent>
      <DialogActions>
        <Button text={t("deleteDialog.cancel")} onClick={props.onClose} />
        <Button text={t("deleteDialog.delete")} onClick={props.delete} />
      </DialogActions>
    </Dialog>
  )
}