import { Image, ImageApi } from "../client";
import { Api, ApiClient } from "./ApiClient";

export default class ImageRepository {

	static getApiClient() {
		return ApiClient.getClient(Api.ImageApi) as ImageApi
	}

	static async createImage(image: Blob, storeS3?: boolean): Promise<Image | undefined> {
		const api = this.getApiClient();
		var result;
		try {
			result = await api.createImage(storeS3 ?? false ,image);
		} catch (error) {
			console.log(error);
			throw Error(error as string);
		}
		return result;
	}

	static async getAllImages(): Promise<Image[] | undefined> {
		const api = this.getApiClient();
		var result;
		try {
			result = await api.getAllImages();
		} catch (error) {
			console.log(error);
		}
		return result;
	}

	static async getImageById(id: number): Promise<Image | undefined> {
		const api = this.getApiClient();
		var result;
		try {
			result = await api.getImageById(id);
		} catch (error) {
			console.log(error);
		}
		return result;
	}

	static async downloadImageById(id: number): Promise<string | undefined> {
		const api = this.getApiClient();
		var result;
		try {
			result = await api.downloadImageById(id);
		} catch (error) {
			console.log(error);
		}
		return result;
	}

}