import './styles/App.css';
import Router from '../infrastructure/routing/Router';

function App() {
  return (
      <Router />
  );
}

export default App;
