import { Credentials, LoginApi, LoginResponse } from "../client";
import { Api, ApiClient } from "./ApiClient";

export default class LoginRepository {

    static getApiClient() {
        return ApiClient.getClient(Api.LoginApi) as LoginApi
    }

    static async login(credentials: Credentials): Promise<LoginResponse | undefined> {
		const api = this.getApiClient();
		var result;
		try {
			result = await api.login(credentials);
		} catch (error) {
			console.log(error);
			throw Error(error as string);
		}

		return result;
	}

}