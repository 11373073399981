import { useEffect, useState } from "react";
import { TypographyTheme } from "@movicoders/movicoders-components";
import { User } from "../../client";
import { UserViewModel } from "../../viewmodels/UserViewModel";
import UserForm from "./components/UserForm";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { GridColDef, DataGrid } from '@mui/x-data-grid'
import { EditOutlined, DeleteOutlined, AddCircleOutlined } from '@mui/icons-material';
import ConfirmDialog from "../../components/confirmDialog/ConfirmDialog";
import { useManageUsersStyles } from "./components/styles/styles";
import { Grid, IconButton } from "@mui/material";
import SnackBar from "../../components/snackbar/Snackbar";

const ManageUsersView = () => {
    const { t } = useTranslation();
    const classes = useManageUsersStyles();
    const [users, setUsers] = useState<User[]>([]);
    const [open, setOpen] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarError, setSnackbarError] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [openConfirm, setOpenConfirm] = useState(false);
    const [selectedUser, setSelectedUser] = useState<User>({});

    useEffect(() => {
        loadUsers();
    }, [])

    const loadUsers = () => UserViewModel.getInstance().getAllUsers().then((res: User[] | undefined) => {
        if (res) setUsers(res)
    });

    const closeDialog = () => {
        setSelectedUser({});
        setOpen(false)
    }

    const confirmUpdate = (newUser: boolean) => {
        if (newUser) {
            UserViewModel.getInstance().createUser(selectedUser).then(res => {
                loadUsers();
                handleOpenSnackbar(true, false, t("manageUsersView.create.success"));
                closeDialog();
            }).catch(err => {
                handleOpenSnackbar(true, true, t("manageUsersView.create.error"));
            });
        } else {
            UserViewModel.getInstance().updateUser(selectedUser.id!, selectedUser).then(res => {
                loadUsers();
                handleOpenSnackbar(true, false, t("manageUsersView.update.success"));
                setOpenSnackbar(true)
                closeDialog();
            }).catch(err => {
                handleOpenSnackbar(true, true, t("manageUsersView.update.error"));
            });
        }
    }

    const handleCreateUser = () => {
        setSelectedUser({})
        setOpen(true);
    }

    const handleDeleteUser = () => {
        UserViewModel.getInstance().deleteUser(selectedUser.id!).then(res => {
            handleOpenSnackbar(true, false, t("manageUsersView.delete.success"));
            loadUsers();
        }).catch(err => {
            handleOpenSnackbar(true, true, t("manageUsersView.delete.error"));
        })
    }

    const handleOpenSnackbar = (open: boolean, error: boolean, message: string) => {
        setSnackbarMessage(message);
        setSnackbarError(error);
        setOpenSnackbar(open);
    }

    const columns: GridColDef[] = [
        {
            field: "id",
            type: 'number',
            sortable: true,
            disableColumnMenu: false,
            headerAlign: 'center',
            align: 'center',
            headerName: t("manageUsersView.table.id"),
            width: 200
        },
        {
            field: "username",
            type: 'string',
            sortable: true,
            disableColumnMenu: false,
            headerAlign: 'center',
            align: 'center',
            headerName: t("manageUsersView.table.name"),
            width: 200
        },

        {
            field: "profile",
            type: 'string',
            sortable: true,
            disableColumnMenu: false,
            headerAlign: 'center',
            align: 'center',
            headerName: t("manageUsersView.table.role"),
            width: 200,
            renderCell: (params) => `${t(`manageUsersView.profile.${params.value}`)}`
        },
        {
            field: "woocommerceId",
            type: 'string',
            sortable: true,
            disableColumnMenu: false,
            headerAlign: 'center',
            align: 'center',
            headerName: t("manageUsersView.table.woocommerceId"),
            width: 200
        },
        {
            field: 'buttons',
            headerName: " ",
            width: 120,
            filterable: false,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {

                const handleEditResource = () => {
                    let row = JSON.parse(JSON.stringify(params.row))
                    row.password = "";
                    setSelectedUser(row);
                    setOpen(true);
                }

                const handleDeleteResource = () => {
                    setSelectedUser(JSON.parse(JSON.stringify(params.row)));
                    setOpenConfirm(true);
                }

                return (
                    <>
                        <IconButton onClick={handleEditResource}>
                            <EditOutlined />
                        </IconButton>
                        <IconButton onClick={handleDeleteResource}>
                            <DeleteOutlined />
                        </IconButton>
                    </>
                );
            }
        }
    ];


    return (
        <Grid container className={classes.parentContainer}>
            <Grid item xs={10} md={10} lg={6}>
                <Grid item xs={12} container justifyContent="space-between">
                    <TypographyTheme text={t('manageUsersView.table.title')} variant="h3" />
                    <IconButton onClick={handleCreateUser}>
                        <AddCircleOutlined fontSize='large' />
                    </IconButton>
                </Grid>
                <DataGrid
                    rows={users}
                    columns={columns}
                    checkboxSelection={false}
                    autoHeight
                />
            </Grid>
            <UserForm user={selectedUser} open={open} setOpen={setOpen} save={() => confirmUpdate(selectedUser.id === undefined)} />
            <ConfirmDialog
                open={openConfirm}
                onClose={() => { setOpenConfirm(false) }}
                handleConfirm={() => {
                    handleDeleteUser();
                    setOpenConfirm(false);
                }}
                title={`${t("manageUsersView.confirmDialog.title")} ${selectedUser.id}`}
                body={t("manageUsersView.confirmDialog.body")}
            />
            <SnackBar open={openSnackbar} autoHideDuration={2000} severity={snackbarError ? "error" : "success"} message={snackbarMessage} onClose={(value: boolean) => setOpenSnackbar(value)} />
        </Grid >
    );
}

export default observer(ManageUsersView);