import { Button, TextField, TypographyTheme } from "@movicoders/movicoders-components";
import OwnColors from "../../constants/OwnColors";
import { InputAdornment, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoginViewModel } from "../../viewmodels/LoginViewModel";
import { useState } from "react";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useHistory } from "react-router-dom";
import { ApplicationPaths } from "../../constants/Application";
import { observer } from "mobx-react";
import { useLoginStyles } from "./styles";
import SnackBar from "../../components/snackbar/Snackbar";

const LoginView = () => {
    const { t } = useTranslation();
    const classes = useLoginStyles();
    const [user, setUser] = useState({ username: "", password: "" });
    const [showPassword, setShowPassword] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const history = useHistory();

    const login = () => {
        LoginViewModel.getInstance().login(user).then(res => {
            if (res) history.push(ApplicationPaths.HOME)
            else setOpenSnackbar(true)
        });
    }

    return (
        <div>
            <header className={classes.header}>
                <div className={classes.headerContainer}>
                    <h1 className={classes.headerText}>Iniciar Sesión</h1>
                </div>
                <span className={classes.headerOverlay}></span>
            </header>
            <div className={classes.parentContainer}>
                <div className={classes.contentContainer}>
                    <div style={{ marginBottom: "20px" }} >
                        <TypographyTheme text={t("loginView.username")} classTypography="text" />
                        <TextField
                            onChange={(value: any) => { user.username = value.target.value }}
                            variant="outlined"
                            required={true}
                            placeholder={t("loginView.username.placeholder")}
                            width={"100%"} />
                    </div>
                    <div style={{ marginBottom: "20px" }} >
                        <TypographyTheme text={t("loginView.password")} classTypography="text" />
                        <TextField
                            onChange={(value: any) => { user.password = value.target.value }}
                            variant="outlined"
                            required={true}
                            placeholder={t("loginView.password.placeholder")}
                            width={"100%"}
                            type={showPassword ? "text" : "password"}
                            inputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => { setShowPassword(!showPassword) }}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </div>
                    <Button
                        text={t("loginView.login")}
                        onClick={() => login()}
                        style={{ margin: "auto", fontWeight: "bold" }}
                        textColor="white !important"
                        hoverColor="white !important"
                        backgroundColor={`${OwnColors.buttonBlack} !important`}
                        hoverBackgroundColor={`${OwnColors.buttonBlackHover}  !important`}
                    />
                </div>
            </div>
            <SnackBar open={openSnackbar} autoHideDuration={2000} severity={"error"} message={t("loginView.error")} onClose={(value: boolean) => setOpenSnackbar(value)} />
        </div>
    );
}

export default observer(LoginView);