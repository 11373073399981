import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import loginImage from  "../../assets/images/login-header.jpg";

export const useLoginStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            backgroundImage: `url(${loginImage})`,
            height: "200px"
        },
        headerContainer:{
            position: "relative",
            zIndex: 2
        },
        headerText: {
            color: "white",
            zIndex: 2,
            textAlign: "center",
            marginTop: "0px"
        },
        headerOverlay: {
            opacity: 0.8,  
            backgroundColor: "#000", 
            width: "100%", 
            height: "100%", 
            display: "block", 
            zIndex: 1, 
            marginTop: "-64px"
        },
        parentContainer: { 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            marginTop: "40px" 
        },
        contentContainer: { 
            height: "max-content", 
            width: "560px", 
            border: "2px solid #eaeaea", 
            padding: "20px" 
        }
    })
)