import AppSkeleton from '../../components/skeleton/skeleton/AppSkeleton';
import LoginView from '../../views/login/LoginView';
import ManageUsersView from '../../views/manageUsers/ManageUsersView';
import ShinpadTemplateView from '../../views/shinpadTemplate/ShinpadTemplateView';
import LoadingCircle from '../../components/loading/LoadingCircle';
import ManageResourcesView from '../../views/manageResources/ManageResourcesView';
import ManageOrdersView from '../../views/manageOrders/ManageOrdersView';
import EditUserView from '../../views/editUser/EditUserView';

export function loadingApp(props: any) { return <AppSkeleton component={<LoadingCircle {...props} /> } /> }
export function customView(props: any) { return <AppSkeleton component={ <ShinpadTemplateView {...props} /> } /> }
export function loginVIew(props:any) { return <AppSkeleton component={ <LoginView {...props} /> } /> }
export function manageUsersVIew(props:any) { return <AppSkeleton component={ <ManageUsersView {...props} /> } /> }
export function manageResourcesView(props:any) { return <AppSkeleton component={ <ManageResourcesView {...props} /> } /> }
export function manageOrdersView(props:any) { return <AppSkeleton component={ <ManageOrdersView {...props} /> } /> }
export function editUserView(props:any) { return <AppSkeleton component={ <EditUserView {...props} /> } /> }