export const removeBackground = async (blob: Blob) =>
  fetch(process.env.REACT_APP_RMBG + "/remove-background?base64=false", {
    method: "POST",
    body: new File([blob], "image.jpeg", { type: "image/jpeg" }),
    headers: {
      "Content-Type": "image/jpeg",
      "Cross-Origin": "*",
      "Access-Control-Allow-Origin": "*",
    },
  }).then(async (res: any) => {
    if (res?.status !== 200) throw new Error(res.status);
    res = await res.blob();
    return URL.createObjectURL(new Blob([res]));
  });
