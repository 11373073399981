export const Fonts = [
    'Calibri',
    'Attack Graffiti',
    'American Captain',
    'Andreas',
    'Batman Forever',
    'Bellyn Italic',
    'Blank River',
    'Boldness',
    'Circus Of Innocents',
    'College Halo',
    'Desonanz',
    'Distortion of the Brain and Mind',
    'Doctor Glitch',
    'EthnocentricRg italic',
    'EthnocentricRg',
    'Flash Rogers',
    'Higher',
    'Justice Leage',
    'Masker Area',
    'Monoton',
    'Network',
    'NFL RedZone',
    'Octuple',
    'PaybAck',
    'Raceline Demo',
    'Raider Crusader',
    'Raider Crusader Condensed',
    'Raider Crusader Expanded',
    'Raider Crusader Italic',
    'Redford BV',
    'Sarcaland Brusher',
    'Snickers',
    'Space Wham',
    'Sportsquake',
    'Street Ruler',
    'Thorsley',
    'Tiger Walk',
    'Tunning Oxide',
    'X-Heighting',
]