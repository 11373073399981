import { Grid, Typography } from "@mui/material";
import { Button, FileUploader } from "@movicoders/movicoders-components";
import { useTranslation } from "react-i18next";
import OwnColors from "../../../../../constants/OwnColors";
import { useShinpadTemplateStyles } from "../../styles/styles";

type TImageMenu = {
    addImage: (value: string, extension?: string) => void
    changeTab: (value: number) => void
}
export const ImageMenu = ({ addImage, changeTab }: TImageMenu) => {
    const { t } = useTranslation();
    const classes = useShinpadTemplateStyles();

    return (
        <Grid container style={{ padding: "5%", height: "100%" }}>
            <Grid item container xs={12} style={{ height: "fit-content" }}>
                <div className={classes.numberDiv}>4</div>
                <Typography style={{ color: "white", fontSize: "2rem" }}>{t("shinpadTemplateView.leftMenu.addImage")}</Typography>
                <Typography style={{ color: "#cccccc" }}>{t("shinpadTemplateView.leftMenu.available-formats")}</Typography>
                <div style={{ margin: "10px 0 10px 0", width: "100%", backgroundColor: OwnColors.podoactivaBlack3, color: "white", border: 'solid white', borderRadius: '5px' }}>
                    <FileUploader
                        classNameDropzone={classes.imageDropzone}
                        classNameParagraphDropzone={classes.imageParagraphDropzone}
                        maxFiles={20}
                        onChange={(e: File[]) => {
                            try {
                                if(e.length > 0){
                                    let aceptedFormats = ["jpg", "png", "jpeg","jfif", "pjp", "pjpeg"];
                                    if (e.length > 0 && aceptedFormats.includes(e[e.length -1]?.name.split(".").pop() ?? "")) {
                                        addImage(window.webkitURL.createObjectURL(e[e.length -1]), e[e.length -1]?.name.split(".").pop() ?? "")
                                    }
                                }
                            } catch (e) {
                                alert(t("shinpadTemplateView.leftMenu.addImage.error"))
                            }
                        }}
                        maxFileSize={10485760} //10Mb
                        acceptedFiles={["image/jpeg", "image/png"]}
                        showPreviews={false}
                        showPreviewsInDropzone={false}
                        dropzoneText={t("shinpadTemplateView.leftMenu.addImage.dropText")}
                        getDropRejectMessage={() => { return (t("shinpadTemplateView.leftMenu.addImage.reject")) }}
                        getFileAddedMessage={() => { return (t("shinpadTemplateView.leftMenu.addImage.added")) }}
                    />
                </div>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item container xs={12} sm={12} md={6}>
                        <Button
                            text={t("shinpadTemplateView.leftMenu.previous")}
                            onClick={() => { changeTab(3) }}
                            style={{ fontWeight: "bold", width: "100%" }}
                            textColor="white !important"
                            hoverColor="black !important"
                            backgroundColor={`${OwnColors.podoactivaGrey} !important`}
                            hoverBackgroundColor={`white  !important`}
                        />
                    </Grid>
                    <Grid item container xs={12} sm={12} md={6}>
                        <Button
                            text={t("shinpadTemplateView.leftMenu.next")}
                            onClick={() => { changeTab(5) }}
                            style={{ fontWeight: "bold", width: "100%", border: "2px solid white" }}
                            textColor="white !important"
                            hoverColor="white !important"
                            backgroundColor={`${OwnColors.buttonBlack} !important`}
                            hoverBackgroundColor={`${OwnColors.buttonBlackHover}  !important`}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );

}