import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem, FormControl, FormHelperText } from "@mui/material";
import { Button, TypographyTheme, Switch, FileUploader } from "@movicoders/movicoders-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Resource, ResourceTypeEnum } from "../../../client";
import ImageRepository from "../../../repositories/ImageRepository";
import { ResourceViewModel } from "../../../viewmodels/ResourceViewModel";
import { useManageResourcesStyles } from "./styles/styles";
import OwnColors from "../../../constants/OwnColors";

type TResourcesForm = {
    resource: Resource
    open: boolean
    setOpen: (value: boolean) => void
    updateList: () => void
    openSnackbar: (open: boolean, error: boolean, message: string) => void
}
const ResourcesForm = ({ resource, open, setOpen, updateList, openSnackbar }: TResourcesForm) => {
    const { t } = useTranslation();
    const classes = useManageResourcesStyles();
    const [typeError, setTypeError] = useState(false)
    const [imageError, setImageError] = useState(false)
    const [iconError, setIconError] = useState(false)
    const [file, setFile] = useState<File>()
    const [icon, setIcon] = useState<File>()

    const handleClose = () => {
        setOpen(false);
        setFile(undefined);
        setTypeError(false);
        setImageError(false)
    }

    const handleSave = () => {
        if (resource.type === undefined) {
            setTypeError(true)
        }
        else if (resource.image === undefined && file === undefined) {
            setImageError(true)
        } else if (resource.icon === undefined && icon === undefined) {
            setIconError(true)
        } else {
            if (resource.id === undefined) {
                ImageRepository.createImage(file!).then(res => {
                    if (res) {
                        resource.image = res
                        ImageRepository.createImage(icon!, true).then(iconImage => {
                            if (iconImage) {
                                resource.icon = iconImage;
                                ResourceViewModel.getInstance().createResource(resource).then(res => {
                                    openSnackbar(true, false, t("manageResourcesView.create.success"));
                                    updateList();
                                    handleClose();
                                }).catch(err => {
                                    openSnackbar(true, true, t("manageResourcesView.create.error"));
                                });
                            }
                        }).catch(err => {
                            openSnackbar(true, true, t("manageResourcesView.create.error"))
                        })
                    }
                }).catch(err => {
                    openSnackbar(true, true, t("manageResourcesView.create.error"))
                });
            } else {
                ResourceViewModel.getInstance().updateResource(resource.id, resource).then(res => {
                    openSnackbar(true, false, t("manageResourcesView.update.success"));
                    updateList();
                    handleClose();
                }).catch(err => {
                    openSnackbar(true, true, t("manageResourcesView.update.error"));
                });
            }
        }
    }

    const handleChangeSwitch = (event: boolean) => {
        resource.active = event;
    };

    return (
        <Dialog fullWidth={false} maxWidth="xs" open={open} onClose={handleClose}>
            <DialogTitle className={classes.dialogTitle}>{t("manageResourcesView.form.title")}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item container xs={12} className={classes.dialogFileUploader}>
                        <Grid item xs={12}>
                            <TypographyTheme text={t("manageResourcesView.form.main")} />
                        </Grid>
                        {resource.image !== undefined ?
                            <img src={resource.icon?.bucketUrl} alt={resource.image?.fileName} />
                            :
                            <FormControl error={imageError}>
                                <FileUploader
                                    maxFiles={1}
                                    onChange={(e: File[]) => {
                                        if (e.length > 0) {
                                            setFile(e.at(-1));
                                        }
                                    }}
                                    maxFileSize={10485760} //10Mb
                                    acceptedFiles={[".jpg", ".png", ".jpeg"]}
                                    showPreviews={false}
                                    showPreviewsInDropzone={true}
                                />
                                {imageError && <FormHelperText>{t("form.requiredField")}</FormHelperText>}
                            </FormControl>
                        }
                    </Grid>
                    <Grid item container xs={12} className={classes.dialogFileUploader}>
                        <Grid item xs={12}>
                            <TypographyTheme text={t("manageResourcesView.form.icon")} />
                        </Grid>
                        {resource.icon !== undefined ?
                            <img src={resource.icon?.bucketUrl} alt={resource.icon?.fileName} width={100} height={100}/>
                            :
                            <FormControl error={iconError}>
                                <FileUploader
                                    maxFiles={1}
                                    onChange={(e: File[]) => {
                                        if (e.length > 0) {
                                            setIcon(e.at(-1));
                                        }
                                    }}
                                    maxFileSize={3000000} //3Mb
                                    acceptedFiles={[".jpg", ".png", ".jpeg"]}
                                    showPreviews={false}
                                    showPreviewsInDropzone={true}
                                />
                                {iconError && <FormHelperText>{t("form.requiredField")}</FormHelperText>}
                            </FormControl>
                        }
                    </Grid>
                    <Grid item xs={12} className={classes.dialogCenterGrid}>
                        <TypographyTheme text={t("manageResourcesView.form.type")} />
                        <FormControl error={typeError}>
                            <Select
                                onChange={(item: any) => { resource.type = item.target.value as ResourceTypeEnum }}
                                variant="outlined"
                                defaultValue={resource.type}
                                autoWidth
                                style={{ minWidth: "150px" }}
                            >
                                <MenuItem value={ResourceTypeEnum.BACKGROUND}>{t("manageResourcesView.table.header.type.BACKGROUND")}</MenuItem>
                                <MenuItem value={ResourceTypeEnum.ELEMENT}>{t("manageResourcesView.table.header.type.ELEMENT")}</MenuItem>
                            </Select>
                            {typeError && <FormHelperText>{t("form.requiredField")}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} className={classes.dialogCenterGrid}>
                        <TypographyTheme text={t("manageResourcesView.form.active")} />
                        <Switch checked={resource.active} onCheckedChanged={handleChangeSwitch} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button text={t("manageUsersView.form.cancel")}
                    onClick={handleClose}
                    style={{ fontWeight: "bold" }}
                    textColor="white !important"
                    hoverColor="white !important"
                    backgroundColor={`${OwnColors.buttonBlack} !important`}
                    hoverBackgroundColor={`${OwnColors.buttonBlackHover}  !important`} />
                <Button text={t("manageUsersView.form.save")}
                    onClick={handleSave}
                    style={{ fontWeight: "bold" }}
                    textColor="white !important"
                    hoverColor="white !important"
                    backgroundColor={`${OwnColors.buttonBlack} !important`}
                    hoverBackgroundColor={`${OwnColors.buttonBlackHover}  !important`} />
            </DialogActions>
        </Dialog>
    );

}

export default ResourcesForm;