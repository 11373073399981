import { User } from "../client"

export class SessionStore {

    static getToken() {
        const token = localStorage.getItem('token')
        return token || ""
    }

    static setToken(token: string) {
        localStorage.setItem('token', token)
    }

    static getCurrentPath() {
        if (!localStorage.getItem('currentPath'))
            localStorage.setItem('currentPath', "/")
        return localStorage.getItem('currentPath')!!
    }

    static setCurrentPath(path: string) {
        localStorage.setItem('currentPath', path)
    }

    static setLocalStorageUser(user: User | undefined) {
        if (user != null && user != undefined) {
            localStorage.setItem("profile", user.profile!);
            localStorage.setItem("userId", user!.id!.toString());
            localStorage.setItem("username", user!.username!);
        } else {
            localStorage.setItem("profile", "");
            localStorage.setItem("userId", "");
            localStorage.setItem("username", "");
        }

    }

    static getLocalStorageUser() {
        return [localStorage.getItem("userId"), SessionStore.getToken()];
    }

}