import OwnColors from "../../../../../constants/OwnColors";
import { Grid, Typography, Button as MuiButton } from "@mui/material";
import {
  IconButton,
  TypographyTheme,
  Button,
} from "@movicoders/movicoders-components";
import { useTranslation } from "react-i18next";
import { ResourceViewModel } from "../../../../../viewmodels/ResourceViewModel";
import { useShinpadTemplateStyles } from "../../styles/styles";
import { Resource } from "../../../../../client";
import ImageButton from "../../../../../components/imageButton/ImageButton";
import React from "react";
import { Close } from "@mui/icons-material";

type TBackground = {
  setBackgroundColor: (value: string[]) => void;
  backgroundColor: string;
  changeTab: (value: number) => void;
};
export const BackgroundMenu = React.memo(
  ({ setBackgroundColor, backgroundColor, changeTab }: TBackground) => {
    const { t } = useTranslation();
    const classes = useShinpadTemplateStyles();

    const changeBakgroundImage = (bg: Resource) => {
      ResourceViewModel.getInstance()
        .transformBackground(bg)
        .then((res) => {
          let url = bg.image?.downloadUrl;
          let extension = bg.image?.mimeType?.split("/")[1];
          setBackgroundColor([url!, extension!]);
        });
    };

    return (
      <Grid container style={{ padding: "5%", height: "100%" }}>
        <Grid item container xs={12} style={{ height: "fit-content" }}>
          <div className={classes.numberDiv}>2</div>
          <Typography style={{ color: "white", fontSize: "2rem" }}>
            Elige tu diseño
          </Typography>
          <TypographyTheme text={"Selecciona un color sólido"} color="white" />
          <Grid
            style={{
              display: "flex",
              flexWrap: "wrap",
              columnGap: "5px",
              rowGap: "5px",
              margin: "10px 0 10px 0",
            }}
          >
            {OwnColors.backgroundColors.map((color: string) => (
              <MuiButton
                onClick={() => setBackgroundColor([color!])}
                style={{
                  minWidth: 40,
                  width: 40,
                  height: 40,
                  backgroundColor: color,
                }}
              />
            ))}
            <IconButton
              icon={<Close />}
              backgroundColor={"#ffffff"}
              color="black"
              onClick={() => setBackgroundColor([OwnColors.defaultBackground])}
              classIconButton={classes.backgroundIcon}
            />
          </Grid>
          {ResourceViewModel.getInstance().backgrounds.length > 0 && (
            <Grid item xs={12}>
              <TypographyTheme text="Imagen de fondo" color="white" />
              <Grid
                style={{
                  margin: "10px 0 10px 0",
                  display: "flex",
                  columnGap: "5px",
                  rowGap: "5px",
                  flexWrap: "wrap",
                }}
              >
                {ResourceViewModel.getInstance().backgrounds.map(
                  (res: Resource) => {
                    let icon = res.icon?.bucketUrl;
                    return (
                      <ImageButton
                        onClick={() => changeBakgroundImage(res)}
                        image={icon}
                        imageName={res.icon?.fileName}
                        isSelected={backgroundColor[0] === res}
                      />
                    );
                  }
                )}
                <IconButton
                  icon={<Close />}
                  style={{ width: "100px", height: "100px", borderRadius: 0 }}
                  backgroundColor={"white"}
                  color="black"
                  onClick={() =>
                    setBackgroundColor([OwnColors.defaultBackground])
                  }
                  classIconButton={classes.backgroundIcon}
                />
              </Grid>
            </Grid>
          )}

          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item container xs={12} sm={12} md={6}>
              <Button
                text={t("shinpadTemplateView.leftMenu.previous")}
                onClick={() => {
                  changeTab(1);
                }}
                style={{ fontWeight: "bold", width: "100%" }}
                textColor="white !important"
                hoverColor="black !important"
                backgroundColor={`${OwnColors.podoactivaGrey} !important`}
                hoverBackgroundColor={`white  !important`}
              />
            </Grid>
            <Grid item container xs={12} sm={12} md={6}>
              <Button
                text={t("shinpadTemplateView.leftMenu.next")}
                onClick={() => {
                  changeTab(3);
                }}
                style={{ fontWeight: "bold", width: "100%", border: "2px solid white" }}
                textColor="white !important"
                hoverColor="white !important"
                backgroundColor={`${OwnColors.buttonBlack} !important`}
                hoverBackgroundColor={`${OwnColors.buttonBlackHover}  !important`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);
