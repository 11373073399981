import { Fragment, useEffect, useState } from "react";
import { ButtonBase, CircularProgress } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';

type TImageButton = {
  isSelected?: boolean
  onClick: () => void
  image?: string
  imageName?: string
}
const ImageButton = (props: TImageButton) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if(props.image === undefined){
      loadedImage();
      errorImage();
    }
  }, [])

  const loadedImage = () => {
    setLoading(false)
  }

  const errorImage = () => {
    setError(true)
  }

  return (
    <Fragment>
      {!error && <div style={{ display: loading ? "block" : "none" }}>
        <div style={{ width: "100px", height: "100px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "4px" }}>
          <CircularProgress style={{ margin: "auto" }} />
        </div>
      </div>}
      {error && <div style={{ width: "100px", height: "100px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "4px" }}>
          <ErrorIcon style={{ margin: "auto" }} />
        </div>}
      {!error && <ButtonBase onClick={props.onClick} style={{ display: loading ? 'none' : "block", width: "100px", height: "100px", borderRadius: "4px" }} >
        <img alt={props.imageName} src={props.image} onLoad={loadedImage} onError={errorImage} width="100px" height="100px" style={{ borderRadius: "4px" }} />
      </ButtonBase>}
    </Fragment>
  )
}

export default ImageButton;