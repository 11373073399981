/* tslint:disable */
/* eslint-disable */
/**
 * Podoactiva Backend Api Documentation
 * Podoactiva Backend Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Order,
    OrderFromJSON,
    OrderToJSON,
} from '../models';

export interface CreateOrderRequest {
    order?: Order;
}

export interface DeleteOrderByIdRequest {
    id: number;
}

export interface GetOrderByIdRequest {
    id: number;
}

export interface GetOrdersFilteredByUserRequest {
    userId: number;
}

export interface UpdateOrderByIdRequest {
    id: number;
    status?: UpdateOrderByIdStatusEnum;
}

/**
 * no description
 */
export class OrderApi extends runtime.BaseAPI {

    /**
     * Creates a order
     * createOrder
     */
    async createOrderRaw(requestParameters: CreateOrderRequest): Promise<runtime.ApiResponse<Order>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderToJSON(requestParameters.order),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderFromJSON(jsonValue));
    }

    /**
     * Creates a order
     * createOrder
     */
    async createOrder(order?: Order): Promise<Order> {
        const response = await this.createOrderRaw({ order: order });
        return await response.value();
    }

    /**
     * Deletes a order by its id.
     * deleteOrderById
     */
    async deleteOrderByIdRaw(requestParameters: DeleteOrderByIdRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOrderById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a order by its id.
     * deleteOrderById
     */
    async deleteOrderById(id: number): Promise<void> {
        await this.deleteOrderByIdRaw({ id: id });
    }

    /**
     * Retrieves all orders
     * getAllOrders
     */
    async getAllOrdersRaw(): Promise<runtime.ApiResponse<Array<Order>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderFromJSON));
    }

    /**
     * Retrieves all orders
     * getAllOrders
     */
    async getAllOrders(): Promise<Array<Order>> {
        const response = await this.getAllOrdersRaw();
        return await response.value();
    }

    /**
     * Retrieves a order by its id
     * getOrderById
     */
    async getOrderByIdRaw(requestParameters: GetOrderByIdRequest): Promise<runtime.ApiResponse<Order>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOrderById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderFromJSON(jsonValue));
    }

    /**
     * Retrieves a order by its id
     * getOrderById
     */
    async getOrderById(id: number): Promise<Order> {
        const response = await this.getOrderByIdRaw({ id: id });
        return await response.value();
    }

    /**
     * Retrieves all orders filtered by user.
     * getOrdersFilteredByUser
     */
    async getOrdersFilteredByUserRaw(requestParameters: GetOrdersFilteredByUserRequest): Promise<runtime.ApiResponse<Array<Order>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getOrdersFilteredByUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/filter/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderFromJSON));
    }

    /**
     * Retrieves all orders filtered by user.
     * getOrdersFilteredByUser
     */
    async getOrdersFilteredByUser(userId: number): Promise<Array<Order>> {
        const response = await this.getOrdersFilteredByUserRaw({ userId: userId });
        return await response.value();
    }

    /**
     * Updates a order by its id
     * updateOrderById
     */
    async updateOrderByIdRaw(requestParameters: UpdateOrderByIdRequest): Promise<runtime.ApiResponse<Order>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOrderById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderFromJSON(jsonValue));
    }

    /**
     * Updates a order by its id
     * updateOrderById
     */
    async updateOrderById(id: number, status?: UpdateOrderByIdStatusEnum): Promise<Order> {
        const response = await this.updateOrderByIdRaw({ id: id, status: status });
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum UpdateOrderByIdStatusEnum {
    PENDING = 'PENDING',
    CONFIRMED = 'CONFIRMED',
    CANCELLED = 'CANCELLED',
    FINISHED = 'FINISHED'
}
