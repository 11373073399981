import { Order, OrderApi, UpdateOrderByIdStatusEnum } from "../client";
import { Api, ApiClient } from "./ApiClient";

export default class OrderRepository {

    static getApiClient() {
        return ApiClient.getClient(Api.OrderApi) as OrderApi
    }

    static async updateOrder(id: number, status: UpdateOrderByIdStatusEnum): Promise<Order | undefined> {
		const api = this.getApiClient();
		var result;
		try {
			result = await api.updateOrderById(id, status);
		} catch (error) {
			console.log(error);
			throw Error(error as string);
		}

		return result;
	}

	static async createOrder(order: Order): Promise<Order | undefined> {
		const api = this.getApiClient();
		var result;
		try {
			result = await api.createOrder(order);
		} catch (error) {
			console.log(error);
			throw Error(error as string);
		}

		return result;
	}

	static async deleteOrder(id: number): Promise<void> {
		const api = this.getApiClient();
		var result;
		try {
			result = await api.deleteOrderById(id);
		} catch (error) {
			console.log(error);
			throw Error(error as string);
		}

		return result;
	}

	static async getAllOrders(): Promise<Order[] | undefined> {
		const api = this.getApiClient();
		var result;
		try {
			result = await api.getAllOrders();
		} catch (error) {
			console.log(error);
		}

		return result;
	}

	static async getOrderById(id: number): Promise<Order | undefined> {
		const api = this.getApiClient();
		var result;
		try {
			result = await api.getOrderById(id);
		} catch (error) {
			console.log(error);
		}

		return result;
	}

	static async getOrdersFilteredByUser(userId: number):Promise<Order[] | undefined> {
		const api = this.getApiClient();
		var result;
		try {
			result = await api.getOrdersFilteredByUser(userId);
		} catch (error) {
			console.log(error);
		}

		return result;
	}

}