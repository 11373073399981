import { Button } from "@movicoders/movicoders-components";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ProductMenuAttributes from "../../../interfaces/ProductMenuAttributes";
import { Materials } from "../../../constants/Application";

type TFinishPurchaseDialog = {
  handleConfirm: () => Promise<void>;
  onClose: () => void;
  open: boolean;
  productAttributes: ProductMenuAttributes;
  images: any[];
};
const FinishPurchaseDialog = (props: TFinishPurchaseDialog) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handelBuy = () => {
    setLoading(true);
    props.handleConfirm();
  };

  const getMaterialName = () => {
    const material = Object.values(Materials).find(material => material.id === props.productAttributes.material)
    return t(material?.translation ?? "product.material.normal")
  }

  const LoadingGrid = ({ buy }: { buy: boolean }) => {
    return (
      <Grid container>
        <Grid item style={{ textAlign: "center", margin: "auto" }}>
          <img
            alt="loading-gif"
            src="https://media.giphy.com/media/WvuTFk2IN7jxoLVDkP/giphy.gif"
            width={200}
            height={200}
          />
          <p>{t(buy ? "buy.processing" : "buy.image")}</p>
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t("shinpadTemplateView.confirmBuy.title")}</DialogTitle>
      <DialogContent>
        {loading ? (
          <LoadingGrid buy={true} />
        ) : (
          <Grid container>
            <Grid item container xs={12} justifyContent="space-around">
              {props.images[0] ? (
                <img src={props.images[0]} alt="preview" width={"500px"} />
              ) : (
                <LoadingGrid buy={false} />
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography display="inline">
                {t("shinpadTemplateView.confirmBuy.size")}
              </Typography>
              <Typography display="inline" style={{ fontWeight: "bold" }}>
                {props.productAttributes.size}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography display="inline">
                {t("shinpadTemplateView.confirmBuy.extra")}
              </Typography>
              <Typography display="inline" style={{ fontWeight: "bold" }}>
                {props.productAttributes.extra}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography display="inline">
                {t("shinpadTemplateView.leftMenu.carbon")}{" "}
              </Typography>
              <Typography display="inline" style={{ fontWeight: "bold" }}>
                {getMaterialName()}
              </Typography>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button text={t("confirmDialog.cancel")} onClick={props.onClose} />
        <Button
          text={t("buy")}
          onClick={handelBuy}
          disabled={props.images[0] === undefined}
        />
      </DialogActions>
    </Dialog>
  );
};

export default FinishPurchaseDialog;
