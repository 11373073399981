import Konva from 'konva';
import { Fragment, useEffect, useRef } from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';
import useLongPress from '../../../customHooks/useLongPress';

type TImageFromFile = {
    image: any,
    isSelected: boolean,
    onSelect: () => void,
    onChange: (newAttr: any) => void
    draggable?: boolean
    handleContextMenu: (event: any) => void
    trRef: any
}
export const ImageFromFile = ({ image, isSelected, onSelect, onChange, draggable = true, handleContextMenu, trRef }: TImageFromFile) => {
    const [img] = useImage(image.src);
    const imageRef = useRef<Konva.Image>();

    /**
     * If the image is selected links the nodes of the transformer to the nodes
     * of the image. When the nodes are moved the image is resized.
     */
    useEffect(() => {
        if (isSelected && draggable) {
            trRef!.current!.attachTo(imageRef!.current!);
            trRef!.current!.getLayer()!.batchDraw();
        }
    }, [isSelected]);

    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };

    const longPressEvent = useLongPress(handleContextMenu, onSelect, defaultOptions);

    return (
        <Fragment>
            <Image
                //This coordinates are provisional a can be changed any moment
                x={image.x}
                y={image.y}
                image={img}
                opacity={image.opacity? image.opacity : 1}
                // The offset coordinates stablish the center of gravity of the image
                // If the image is a rectangle, as it ususally is, then the center should
                // half height and half width
                offsetX={img ? image.width / 2 : 200}
                offsetY={img ? image.height / 2 : 125}

                draggable={draggable}

                // whern dragging the image we set the new coordinates of the image
                onDragEnd={(e) => {
                    onChange({
                        ...image,
                        x: e.target.x(),
                        y: e.target.y(),
                    })
                }}

                onclick={onSelect}
                onTap={onSelect} // For mobile

                ref={imageRef}
                {...image}

                onTransformEnd={(e) => {
                    const node = imageRef.current;

                    //Sets the new coordinates and dimensions while transforming
                    onChange({
                        ...image,
                        x: node!.x(),
                        y: node!.y(),
                        scaleX: node?.scaleX(),
                        scaleY: node?.scaleY(),
                        rotation: node?.rotation(),
                        width: Math.max(5, node!.width()),
                        height: Math.max(node!.height())
                    })
                }}
                onContextMenu={handleContextMenu}
                {...longPressEvent}
            />
        </Fragment>
    )


}