import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem, FormHelperText } from "@mui/material";
import { Button, TextField, TypographyTheme } from "@movicoders/movicoders-components";
import { useState } from "react";
import { User, UserProfileEnum } from "../../../client";
import { useTranslation } from "react-i18next";
import { useManageUsersStyles } from "./styles/styles";
import OwnColors from "../../../constants/OwnColors";

type TUserForm = {
    user: User
    open: boolean
    setOpen: (value: boolean) => void
    save: () => void
}
const UserForm = ({ user, open, setOpen, save }: TUserForm) => {
    const { t } = useTranslation();
    const classes = useManageUsersStyles();
    const [usernameError, setUsernameError] = useState(false);
    const [profileError, setProfileError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const handleClose = () => {
        setUsernameError(false);
        setProfileError(false);
        setPasswordError(false);
        setOpen(false)
    }

    const handleSave = () => {
        if (user.username === "" || user.username === undefined) {
            setUsernameError(true);
        } else {
            setUsernameError(false);
        }
        if (user.profile === undefined) {
            setProfileError(true);
        } else {
            setProfileError(false);
        }
        if ((user.password === "" || user.password === undefined) && user.id === undefined) {
            setPasswordError(true);
        } else {
            setPasswordError(false);
        }
        if (
            (user.username !== "" || user.username !== undefined) &&
            (user.profile !== undefined) &&
            (user.id !== undefined || (user.password !== "" && user.password !== undefined))
        ) {
            save();
        }
    }

    return (
        <Dialog fullWidth={false} maxWidth="xs" open={open} onClose={handleClose}>
            <DialogTitle className={classes.dialogTitle}>Usuario</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.dialogRow} style={{marginTop: "5px"}}>
                        <TypographyTheme text={t("manageUsersView.form.username")} />
                        <TextField variant="outlined" onChange={(e: any) => { user.username = e.target.value }} defaultValue={user.username ?? ""} error={usernameError} errorText={t("form.requiredField")} />
                    </Grid>
                    <Grid item xs={12} className={classes.dialogRow}>
                        <TypographyTheme text={t("manageUsersView.form.role")} />
                        <Select
                            onChange={(item: any) => { user.profile = item.target.value as UserProfileEnum }}
                            variant="outlined"
                            defaultValue={user.profile}
                            autoWidth
                            style={{ minWidth: "150px" }}
                        >
                            <MenuItem value={UserProfileEnum.SHOP}>{t("manageUsersView.profile.SHOP")}</MenuItem>
                            <MenuItem value={UserProfileEnum.ADMIN}>{t("manageUsersView.profile.ADMIN")}</MenuItem>
                        </Select>
                        {profileError && <FormHelperText>{t("form.requiredField")}</FormHelperText>}
                    </Grid>
                    <Grid item xs={12} className={classes.dialogRow}>
                        <TypographyTheme text={t("manageUsersView.form.woocommerceId")} />
                        <TextField variant="outlined" onChange={(e: any) => { user.woocommerceId = e.target.value }} defaultValue={user.woocommerceId ?? ""} />
                    </Grid>
                    <Grid item xs={12} className={classes.dialogRow}>
                        <TypographyTheme text={t("manageUsersView.form.password")} />
                        <TextField variant="outlined" onChange={(e: any) => { user.password = e.target.value }} defaultValue={user.password ?? ""} type="password" error={passwordError} errorText={t("form.requiredField")} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button text={t("manageUsersView.form.cancel")}
                    onClick={handleClose}
                    style={{ fontWeight: "bold", border: "2px solid white" }}
                    textColor="white !important"
                    hoverColor="white !important"
                    backgroundColor={`${OwnColors.buttonBlack} !important`}
                    hoverBackgroundColor={`${OwnColors.buttonBlackHover}  !important`} />
                <Button text={t("manageUsersView.form.save")}
                    onClick={handleSave}
                    style={{ fontWeight: "bold", border: "2px solid white"  }}
                    textColor="white !important"
                    hoverColor="white !important"
                    backgroundColor={`${OwnColors.buttonBlack} !important`}
                    hoverBackgroundColor={`${OwnColors.buttonBlackHover}  !important`} />
            </DialogActions>
        </Dialog>
    );
}

export default UserForm;