import { makeAutoObservable } from "mobx";
import ImageRepository from "../repositories/ImageRepository";

export class ImageViewModel {
    private static instance: ImageViewModel | null = null;

	static getInstance(): ImageViewModel {
		if (!ImageViewModel.instance) {
			ImageViewModel.instance = new ImageViewModel();
		}
		return ImageViewModel.instance;
	}

    private constructor() {
		makeAutoObservable(this);
	}

    public createImage(image: File, storeS3?: boolean) {
        return ImageRepository.createImage(image, storeS3);
    }

    public getAllImages() {
        return ImageRepository.getAllImages();
    }

    public getImageById(id: number) {
        return ImageRepository.getImageById(id);
    }

    public downloadImageById(id: number) {
        return ImageRepository.downloadImageById(id);
    }

}