import { useState } from "react";
import IImage from "../../../../../interfaces/IImage"
import IText from "../../../../../interfaces/IText"
import { Fonts } from "../../../../../constants/Fonts";
import { useShinpadTemplateStyles } from "../../styles/styles";
import { Grid, IconButton, TextField, Select, MenuItem, SelectChangeEvent, FormControl} from "@mui/material";
import { FormatBold, FormatItalic, FormatUnderlined, Gradient } from "@mui/icons-material";
import OwnColors from "../../../../../constants/OwnColors";


type TTextOptionBar = {
    item?: IText,

    items: (IImage | IText)[],
    items2: (IImage | IText)[],
    setItems: (itms: (IImage | IText)[]) => void,
    setItems2: (itms: (IImage | IText)[]) => void

    selectedId: (string | null),
    leftActive: boolean,
}

const TextOptionBar = ({ item, items, items2, setItems, setItems2, selectedId, leftActive }: TTextOptionBar) => {
    let [colorPickerTime, setColorPickerTime] = useState(Date.now());
    const classes = useShinpadTemplateStyles();
    
    /**
     * Changes the bold attribute of the selected text
     * @constant {boolean} item.bold
     */
    const handleBold = () => {
        const itms = leftActive ? items.slice() : items2.slice();
        (itms[itms.findIndex(i => i.id === selectedId)] as IText).bold = !(itms[itms.findIndex(i => i.id === selectedId)] as IText).bold;
        leftActive ? setItems(itms) : setItems2(itms);
    }

    /**
    * Changes the italic attribute of the selected text
    * @constant {boolean} item.italic
    */
    const handleItalics = () => {
        const itms = leftActive ? items.slice() : items2.slice();
        (itms[itms.findIndex(i => i.id === selectedId)] as IText).italic = !(itms[itms.findIndex(i => i.id === selectedId)] as IText).italic;
        leftActive ? setItems(itms) : setItems2(itms);
    }

    /**
    * Changes the underlined attribute of the selected text
    * @constant {boolean} item.underlined
    */
    const handleUnderlined = () => {
        const itms = leftActive ? items.slice() : items2.slice();
        (itms[itms.findIndex(i => i.id === selectedId)] as IText).underlined = !(itms[itms.findIndex(i => i.id === selectedId)] as IText).underlined;
        leftActive ? setItems(itms) : setItems2(itms);
    }

    /**
    * Changes the shadow attribute of the selected text
    * @constant {boolean} item.shadow
    */
    const handleShadow = () => {
        const itms = leftActive ? items.slice() : items2.slice();
        (itms[itms.findIndex(i => i.id === selectedId)] as IText).shadow = !(itms[itms.findIndex(i => i.id === selectedId)] as IText).shadow;
        leftActive ? setItems(itms) : setItems2(itms);
    }

    /**
     * Changes the text font to the one selected in the font selector
     * @param {*} {-} e 
     */
    const handleFontChange = (e: SelectChangeEvent) => {
        const itms = leftActive ? items.slice() : items2.slice();
        (itms[itms.findIndex(i => i.id === selectedId)] as IText).font = e.target.value;
        leftActive ? setItems(itms) : setItems2(itms);
    }

    /**
     * Changes the text of a text item. (It implies some problems when resizing text item)
     * @param {*} e 
     */
    const handleTextChange = (e: string) => {
        const itms = leftActive ? items.slice() : items2.slice();
        (itms[itms.findIndex(i => i.id === selectedId)] as IText).text = e;
        leftActive ? setItems(itms) : setItems2(itms);
    }

    /**
     * Changes selected text color to the one selecte on the color selected
     * @param {*} e 
     */
    const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const itms = leftActive ? items.slice() : items2.slice();
        (itms[itms.findIndex(i => i.id === selectedId)] as IText).color = e.target.value;
        leftActive ? setItems(itms) : setItems2(itms);
    }



    return (

        <Grid item style={{ display: "flex", color: "white", justifyContent: "center", outline: "0 !important"}} >
            <TextField
                defaultValue={item?.text ?? ""}
                onChange={(e: any) => { handleTextChange(e.target.value) }}
                className={classes.optionsText}
                variant="outlined"
                size="small"
            />
            <IconButton onClick={handleBold}>
            {item?.bold ?
                <FormatBold  style={{ color: OwnColors.buttonBlack }}/>
                :
                <FormatBold  style={{ color: "white" }} />}
            </IconButton>
            <IconButton onClick={handleItalics}>
            {item?.italic ?
                <FormatItalic  style={{ color: OwnColors.buttonBlack }}/>
                :
                <FormatItalic  style={{ color: "white" }} />}
            </IconButton>
            <IconButton onClick={handleUnderlined}>
            {item?.underlined ?
                <FormatUnderlined  style={{ color: OwnColors.buttonBlack }}/>
                :
                <FormatUnderlined  style={{ color: "white" }} />}
            </IconButton>
            <IconButton onClick={handleShadow}>
            {item?.shadow ?
                <Gradient  style={{ color: OwnColors.buttonBlack }}/>
                :
                <Gradient  style={{ color: "white" }} />}
            </IconButton>
            <div style={{ width: "10em" }}>
                <FormControl style={{ }}>
                    <Select onChange={handleFontChange} value={item?.font ?? ""} className={classes.selectOptionsFont} variant="outlined" size="small" autoWidth={false} MenuProps={{ className: classes.fontSelectPopOver}} >
                        {Fonts.map(font =>
                            <MenuItem value={font} style={{ fontFamily: font }}>{font}</MenuItem>
                            )}
                    </Select>
                </FormControl>
            </div>
            <input
                type="color"
                value={item?.color ?? "#000000"}
                onChange={(e: any) => {
                    if (Date.now() > colorPickerTime + 100) {
                        setColorPickerTime(Date.now());
                        handleColorChange(e);
                    }
                }
                }
                style={{margin: "10px", marginLeft: "15px"}}
            />
        </Grid>
    )
}

export default TextOptionBar;