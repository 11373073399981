import { rejects } from "assert";
import { User } from "../../client";
import { Application_PROFILE } from "../../constants/Application";
import { SessionStore } from "../../data/SessionStore";
import { UserViewModel } from "../../viewmodels/UserViewModel";
import * as Utils from "./../../helpers/Utils";

export interface SessionManagementObject {
    isAllowed: boolean;
    isLogged: boolean,
    requiresLoad: boolean,
    tokenExpired: boolean
}

const manageSession = (requiresAuth: boolean, allowedRoles: Application_PROFILE[]): SessionManagementObject => {

    let requiresLoad = false;

    if (requiresAuth && !UserViewModel.getInstance().loggedUser) {
        requiresLoad = true;
    }

    return {
        isAllowed: UserViewModel.getInstance().loggedUser?.profile != undefined && allowedRoles.map(e => e.toString()).includes(UserViewModel.getInstance().loggedUser!.profile!.toString()),
        isLogged: UserViewModel.getInstance().loggedUser != undefined,
        requiresLoad: requiresLoad,
        tokenExpired: Utils.checkIfTokenExpired()
    }

}

export const reloadData = () => {
    return new Promise<void>((resolve, rejecct) => {
        const [userId, token] = SessionStore.getLocalStorageUser();
        if (userId && token) {
            UserViewModel.getInstance().getUserById(+userId).then(
                (user: User | undefined) => {
                    UserViewModel.getInstance().setLoggedUser(user);
                    SessionStore.setLocalStorageUser(user);
                    resolve();
                }
            )
                .catch((err) => rejects(err));
        } else {
            resolve();
        }
    });
}

export default manageSession;