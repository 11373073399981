import { Menu, MenuItem, ListItemIcon, ListItemText, Divider } from "@mui/material";
import { FlipToFront, FlipToBack, ArrowUpward, ArrowDownward, Delete } from '@mui/icons-material';
import { useTranslation } from "react-i18next";

type TContextMenu = {
    open: boolean
    onClose: () => void
    anchorPosition: {top: number, left: number} | undefined
    moveToFront: () => void
    moveToBack: () => void
    moveUp: () => void
    moveDown: () => void
    onDelete: () => void
}
const ContextMenu = ({ open, onClose, anchorPosition, moveToFront, moveToBack, moveUp, moveDown, onDelete }: TContextMenu ) => {
    const { t } = useTranslation();

    return(
        <Menu open={open} onClose={onClose} anchorReference="anchorPosition" anchorPosition={anchorPosition}>
                <MenuItem onClick={() => {moveToFront(); onClose()}}>
                    <ListItemIcon>
                        <FlipToFront fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText>{t("shinpadTemplateView.contextMenu.toFront")}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => {moveUp(); onClose()}}>
                    <ListItemIcon>
                        <ArrowUpward fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText>{t("shinpadTemplateView.contextMenu.moveUp")}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => {moveDown(); onClose()}}>
                    <ListItemIcon>
                        <ArrowDownward fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText>{t("shinpadTemplateView.contextMenu.moveDown")}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => {moveToBack(); onClose()}}>
                    <ListItemIcon>
                        <FlipToBack fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText>{t("shinpadTemplateView.contextMenu.toBack")}</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => {onDelete(); onClose()}}>
                    <ListItemIcon>
                        <Delete fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText>{t("shinpadTemplateView.contextMenu.delete")}</ListItemText>
                </MenuItem>
            </Menu>
    );
}

export default ContextMenu;