/* tslint:disable */
/* eslint-disable */
/**
 * Podoactiva Backend Api Documentation
 * Podoactiva Backend Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './';

/**
 * User.
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    profile?: UserProfileEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    woocommerceId?: string;
    /**
     * 
     * @type {Address}
     * @memberof User
     */
    address?: Address;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'profile': !exists(json, 'profile') ? undefined : json['profile'],
        'woocommerceId': !exists(json, 'woocommerceId') ? undefined : json['woocommerceId'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'username': value.username,
        'password': value.password,
        'profile': value.profile,
        'woocommerceId': value.woocommerceId,
        'address': AddressToJSON(value.address),
    };
}

/**
* @export
* @enum {string}
*/
export enum UserProfileEnum {
    SHOP = 'SHOP',
    ADMIN = 'ADMIN'
}


