import { useEffect, useState } from "react";
import { Button, IconButton } from "@movicoders/movicoders-components";
import { Grid, Typography, Select, MenuItem } from "@mui/material";
import {
  carbonSizes,
  Materials,
  shinpadOptionals,
  shinpadSizes,
} from "../../../../../constants/Application";
import { useTranslation } from "react-i18next";
import blackHolder from "../../../../../assets/images/blackHolder.jpg";
import whiteHolder from "../../../../../assets/images/whiteHolder.jpg";
import OwnColors from "../../../../../constants/OwnColors";
import { useShinpadTemplateStyles } from "../../styles/styles";
import ProductMenuAttributes from "../../../../../interfaces/ProductMenuAttributes";
import { Close } from "@mui/icons-material";
import { getItemPrice } from "../../../../../helpers/OrderProcessHelper";

type TProductMenu = {
  changeTab: (value: number) => void;
  attributes: ProductMenuAttributes;
  setAttributes: (attributes: ProductMenuAttributes) => void;
};

export const ProductMenu = ({
  changeTab,
  attributes,
  setAttributes,
}: TProductMenu) => {
  const { t } = useTranslation();
  const classes = useShinpadTemplateStyles();

  const setSize = (size: string) => {
    setAttributes({ ...attributes, size: size });
  };

  const setMaterial = (material: string) => {
    setAttributes({ ...attributes, material: material });
  };

  const setHolder = (holder: string) => {
    setAttributes({ ...attributes, extra: holder });
  };

  const getShinpadSize = () => {
    if(attributes.material === Materials.carbon.id || attributes.material === Materials.flex.id)
      return carbonSizes
    return shinpadSizes
  }

  return (
    <Grid container style={{ padding: "5%", height: "100%" }}>
      <Grid item container xs={12} style={{ height: "fit-content" }}>
        <div className={classes.numberDiv}>1</div>
        <Typography style={{ color: "white", fontSize: "2rem" }}>
          {t("shinpadTemplateView.leftMenu.product.title")}
        </Typography>
        <Typography style={{ color: "#cccccc" }}>
          {t("shinpadTemplateView.leftMenu.product.description")}
        </Typography>
        <Grid
          container
          direction="column"
          style={{ color: "white", margin: "10px 0 10px 0" }}
        >
          <Grid
            container
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Typography
              style={{
                color: "#cccccc",
                fontWeight: "bold",
                marginRight: "2px",
              }}
              display="inline"
            >
              {t("shinpadTemplateView.leftMenu.carbon")}
            </Typography>
          </Grid>
          <Select
            value={attributes.material}
            variant="outlined"
            onChange={(event) => setMaterial(event.target.value)}
            style={{
              color: "white",
              backgroundColor: OwnColors.podoactivaGrey,
            }}
          >
            {Object.values(Materials).map((material, index) => (
              <MenuItem key={index} value={material.id}>
                {t(material.translation)}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid
          container
          direction="column"
          style={{ color: "white", margin: "10px 0 10px 0" }}
        >
          <Typography
            style={{
              color: "#cccccc",
              fontWeight: "bold",
              marginBottom: "4px",
            }}
          >
            {t("shinpadTemplateView.leftMenu.size")}
          </Typography>
          <Select
            value={attributes.size}
            variant="outlined"
            onChange={(event) => setSize(event.target.value)}
            style={{
              color: "white",
              backgroundColor: OwnColors.podoactivaGrey,
            }}
          >
            {getShinpadSize().map((size, index) => (
              <MenuItem key={index} value={size} >
                {size}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid
          container
          direction="column"
          style={{ color: "white", margin: "10px 0 10px 0" }}
        >
          <Grid
            container
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Typography
              style={{
                color: "#cccccc",
                fontWeight: "bold",
                marginRight: "2px",
              }}
              display="inline"
            >
              {t("shinpadTemplateView.leftMenu.extra")}
            </Typography>
            <div
              style={{
                borderRadius: "5px",
                backgroundColor: OwnColors.podoactivaGrey,
                width: "fit-content",
                padding: "5px",
              }}
            >
              <Typography style={{ color: "#cccccc" }} display="inline">
                Precio +9,5€
              </Typography>
            </div>
          </Grid>
          <Grid
            container
            wrap="wrap"
            style={{ columnGap: "5px", rowGap: "5px" }}
          >
            <IconButton
              icon={<></>}
              style={{
                width: "100px",
                height: "100px",
                borderRadius: 0,
                backgroundImage: `url("${whiteHolder}")`,
                backgroundSize: "100px",
                border:
                  attributes.extra === shinpadOptionals[0]
                    ? "4px solid #a4a0a9"
                    : "",
              }}
              backgroundColor={"white"}
              onClick={() => {
                setHolder(shinpadOptionals[0]);
              }}
              classIconButton={classes.backgroundIcon}
            />
            <IconButton
              icon={<></>}
              style={{
                width: "100px",
                height: "100px",
                borderRadius: 0,
                backgroundImage: `url("${blackHolder}")`,
                backgroundSize: "100px",
                border:
                  attributes.extra === shinpadOptionals[1]
                    ? "4px solid #a4a0a9"
                    : "",
              }}
              backgroundColor={"white"}
              onClick={() => {
                setHolder(shinpadOptionals[1]);
              }}
              classIconButton={classes.backgroundIcon}
            />
            <IconButton
              icon={<Close />}
              color="black"
              style={{
                width: "100px",
                height: "100px",
                borderRadius: 0,
                border:
                  attributes.extra === shinpadOptionals[2]
                    ? "4px solid #a4a0a9"
                    : "",
              }}
              backgroundColor={"white"}
              onClick={() => {
                setHolder(shinpadOptionals[2]);
              }}
              classIconButton={classes.backgroundIcon}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}></Grid>
          <Grid item container xs={12} md={6} justifyContent="flex-end">
            <Button
              text={t("shinpadTemplateView.leftMenu.next")}
              onClick={() => {
                changeTab(2);
              }}
              style={{ fontWeight: "bold", width: "95%", border: "2px solid white" }}
              textColor="white !important"
              hoverColor="white !important"
              backgroundColor={`${OwnColors.buttonBlack} !important`}
              hoverBackgroundColor={`${OwnColors.buttonBlackHover}  !important`}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
