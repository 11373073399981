//Import COMPONENTS
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Input,
  Slider,
  Tooltip,
} from "@mui/material";
import { useState } from "react";

//Import ICONS from Material-ui
import {
  AutoFixHigh,
  CleaningServices,
  Flip,
  Opacity,
} from "@mui/icons-material";
import { buildInputFile, execute } from "wasm-imagemagick";

//Import resources from PROYECT
import OwnColors from "../../../../../constants/OwnColors";
import IImage from "../../../../../interfaces/IImage";
import IText from "../../../../../interfaces/IText";
import { removeBackground } from "../../../../../repositories/RemoveBackgroundRepository";
import SnackBar from "../../../../../components/snackbar/Snackbar";

type TImageOptionBar = {
  item?: IImage;
  items: (IImage | IText)[];
  items2: (IImage | IText)[];
  setItems: (itms: (IImage | IText)[]) => void;
  setItems2: (itms: (IImage | IText)[]) => void;
  selectedId: string | null;
  leftActive: boolean;
};

const ImageOptionBar = ({
  item,
  items,
  items2,
  setItems,
  setItems2,
  selectedId,
  leftActive,
}: TImageOptionBar) => {
  const CROMA_COLOR_HEX: string = "rgb(0,255,0)";
  const BACKGROUND_REMOVING_FUZZ: number = 30;
  const [removingBackground, setRemovingBackground] = useState(false);
  const [removingBg, setRemovingBg] = useState(false);
  const [error, setError] = useState<{ message: string; show: boolean }>({
    message: "",
    show: false,
  });

  const handleBackgroundRemove = async () => {
    const itms = leftActive ? items.slice() : items2.slice();
    const index = itms.findIndex((i) => i.id === selectedId);
    setRemovingBg(true);
    const newImage = await imageBackgroundRemove(
      index,
      (itms[index] as IImage).src,
      BACKGROUND_REMOVING_FUZZ,
      CROMA_COLOR_HEX
    );

    if (newImage) {
      (itms[index] as IImage).src = newImage;
      leftActive ? setItems(itms) : setItems2(itms);
      setRemovingBg(false);
    } else {
      setRemovingBg(false);
      console.error("background removing failed: something when wrong ");
    }
  };

  const imageBackgroundRemove = async (
    position: number,
    image: string,
    fuzz: number,
    color: string
  ) => {
    if (fuzz >= 0 && fuzz <= 100) {
      const { outputFiles } = await execute({
        inputFiles: [await buildInputFile(image, "image.png")],
        commands: [
          "convert image.png -fuzz " +
            fuzz +
            "% -transparent " +
            color +
            " output.png",
        ],
      });
      return URL.createObjectURL(outputFiles[0]["blob"]);
    } else {
      console.error(
        "background removing failed: Incorrect fuzz value, it must be [0,100]"
      );
    }
  };

  const handleFlip = () => {
    const itms = leftActive ? items.slice() : items2.slice();
    const index = itms.findIndex((i) => i.id === selectedId);

    const scale = (itms[index] as IImage).scaleX;

    (itms[index] as IImage).scaleX = scale ? -scale : -1;

    leftActive ? setItems(itms) : setItems2(itms);
  };

  const handleOpacity = (opacityLevel: number) => {
    const itms = leftActive ? items.slice() : items2.slice();
    const index = itms.findIndex((i) => i.id === selectedId);

    (itms[index] as IImage).opacity = opacityLevel / 100;

    leftActive ? setItems(itms) : setItems2(itms);
  };

  const handleRemoveBackgroundWithApi = async () => {
    const itms = leftActive ? items.slice() : items2.slice();
    const index = itms.findIndex((i) => i.id === selectedId);
    setRemovingBackground(true);

    const newImage = await removeBackgroundWithApi((itms[index] as IImage).src);

    if (newImage) {
      (itms[index] as IImage).src = newImage;
      leftActive ? setItems(itms) : setItems2(itms);
      setRemovingBackground(false);
    } else {
      setRemovingBackground(false);
      console.error("background removing failed");
    }
  };

  const removeBackgroundWithApi = async (image: string) => {
    return fetch(image)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then(async (blob) => {
        return await removeBackground(blob)
          .then((res) => {
            return res;
          })
          .catch((e) => {
            setError({ message: e.message, show: true });
          });
      })
      .catch((e) => {
        return undefined;
      });
  };

  return (
    <Grid item style={{ display: "flex", color: "white" }}>
      <Box sx={{ width: 250 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Opacity />
          </Grid>
          <Grid item xs>
            <Slider
              value={((item as IImage)?.opacity ?? 1) * 100}
              onChangeCommitted={(e: any, value: any) => {
                handleOpacity(value);
              }}
              aria-labelledby="input-slider"
              style={{ color: OwnColors.buttonBlack }}
              max={100}
            />
          </Grid>
          <Grid item>
            <Input
              value={((item as IImage)?.opacity ?? 1) * 100}
              size="small"
              onChange={(e: any) => {
                handleOpacity(
                  e.target.value === "" ? 0 : Number(e.target.value)
                );
              }}
              inputProps={{
                step: 10,
                min: 0,
                max: 100,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
              style={{ color: OwnColors.buttonBlack }}
            />
          </Grid>
        </Grid>
      </Box>

      <Tooltip title="Elimina el fondo de la imagen" placement="top">
        <IconButton
          disabled={removingBackground}
          onClick={handleRemoveBackgroundWithApi}
          style={{ color: "white !important" }}
        >
          {removingBackground ? (
            <CircularProgress disableShrink size={"25px"} />
          ) : (
            <CleaningServices style={{ color: "white" }} />
          )}
        </IconButton>
      </Tooltip>

      <Tooltip title="Elimina el fondo de la imagen (Croma)" placement="top">
        <IconButton
          disabled={removingBg}
          onClick={handleBackgroundRemove}
          style={{ color: "white !important" }}
        >
          {removingBg ? (
            <CircularProgress disableShrink size={"25px"} />
          ) : (
            <AutoFixHigh style={{ color: "white" }} />
          )}
        </IconButton>
      </Tooltip>

      <IconButton onClick={handleFlip} style={{ color: "white !important" }}>
        <Flip style={{ color: "white" }} />
      </IconButton>
      <SnackBar
        message={error.message}
        onClose={() => setError({ ...error, show: false })}
        open={error.show}
        severity="error"
      />
    </Grid>
  );
};

export default ImageOptionBar;
