/* tslint:disable */
/* eslint-disable */
/**
 * Podoactiva Backend Api Documentation
 * Podoactiva Backend Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Credentials,
    CredentialsFromJSON,
    CredentialsToJSON,
    LoginResponse,
    LoginResponseFromJSON,
    LoginResponseToJSON,
} from '../models';

export interface LoginRequest {
    credentials?: Credentials;
}

/**
 * no description
 */
export class LoginApi extends runtime.BaseAPI {

    /**
     * Endpoint for users to sign in.
     * Login
     */
    async loginRaw(requestParameters: LoginRequest): Promise<runtime.ApiResponse<LoginResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CredentialsToJSON(requestParameters.credentials),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for users to sign in.
     * Login
     */
    async login(credentials?: Credentials): Promise<LoginResponse> {
        const response = await this.loginRaw({ credentials: credentials });
        return await response.value();
    }

}
