/* tslint:disable */
/* eslint-disable */
/**
 * Podoactiva Backend Api Documentation
 * Podoactiva Backend Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Image,
    ImageFromJSON,
    ImageFromJSONTyped,
    ImageToJSON,
} from './';

/**
 * component
 * @export
 * @interface Component
 */
export interface Component {
    /**
     * 
     * @type {number}
     * @memberof Component
     */
    id?: number;
    /**
     * 
     * @type {Array<Image>}
     * @memberof Component
     */
    images?: Array<Image>;
    /**
     * 
     * @type {string}
     * @memberof Component
     */
    structure?: string;
    /**
     * 
     * @type {Image}
     * @memberof Component
     */
    background?: Image;
}

export function ComponentFromJSON(json: any): Component {
    return ComponentFromJSONTyped(json, false);
}

export function ComponentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Component {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'images': !exists(json, 'images') ? undefined : ((json['images'] as Array<any>).map(ImageFromJSON)),
        'structure': !exists(json, 'structure') ? undefined : json['structure'],
        'background': !exists(json, 'background') ? undefined : ImageFromJSON(json['background']),
    };
}

export function ComponentToJSON(value?: Component | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'images': value.images === undefined ? undefined : ((value.images as Array<any>).map(ImageToJSON)),
        'structure': value.structure,
        'background': ImageToJSON(value.background),
    };
}


