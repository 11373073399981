import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import {
  ApplicationPaths,
  Application_PROFILE,
} from "../../constants/Application";
import ProtectedRoute from "./ProtectedRoute";
import * as ViewList from "./ViewList";

export default function Router() {
  const scrollToTop = () => {
    /** Method to move the scrool to the top after the user moves from one section to another */
    window.scrollTo(0, 0);
  };
  return (
    <BrowserRouter>
      <Switch>
        {scrollToTop()}
        <Route
          exact
          path={ApplicationPaths.HOME}
          component={ViewList.customView}
        />
        <Route
          exact
          path={ApplicationPaths.LOGIN}
          component={ViewList.loginVIew}
        />
        <ProtectedRoute
          exact
          path={ApplicationPaths.MANAGE_USERS}
          component={ViewList.manageUsersVIew}
          requiresAuth={true}
          allowedRoles={[Application_PROFILE.PROFILE_ADMIN]}
        />
        <ProtectedRoute
          exact
          path={ApplicationPaths.MANAGE_RESOURCES}
          component={ViewList.manageResourcesView}
          requiresAuth={true}
          allowedRoles={[Application_PROFILE.PROFILE_ADMIN]}
        />
        <ProtectedRoute
          exact
          path={ApplicationPaths.ORDERS}
          component={ViewList.manageOrdersView}
          requiresAuth={true}
          allowedRoles={[
            Application_PROFILE.PROFILE_ADMIN,
            Application_PROFILE.PROFILE_SHOP,
          ]}
        />
        <ProtectedRoute
          exact
          path={ApplicationPaths.USER_EDIT}
          component={ViewList.editUserView}
          requiresAuth={true}
          allowedRoles={[
            Application_PROFILE.PROFILE_ADMIN,
            Application_PROFILE.PROFILE_SHOP,
          ]}
        />
        <Route>{<Redirect to={"/home"} />}</Route>
      </Switch>
    </BrowserRouter>
  );
}
